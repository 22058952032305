import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import types from '../../../../utils/CustomPropTypes';
import { fontVariables, colors } from '../../../../styles/variables';
import Feature from '../Feature';
import {
  shouldDisplaySubsection,
  shouldDisplaySection,
} from '../../../../utils/DifferencesUtils/DifferencesUtils';
import { checkShowShopSelect } from '../../MobileFeatureTable/Section/Section';

const DropdownOpen =
  'https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/DropdownOpen.svg';
const DropdownClose =
  'https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/DropdownClose.svg';

const { weights, families } = fontVariables;

const Section = ({
  section,
  products,
  differencesOnly,
  yesLabel,
  showShopSelectContainer,
  footerKeys,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const getClassName = isOpen ? '' : 'collapsed';
  return (
    checkShowShopSelect(section?.isShopSelect, showShopSelectContainer) && (
      <React.Fragment key={section.uuid}>
        <CollapsibleRow
          className={
            shouldDisplaySection(section, products, differencesOnly)
              ? ''
              : 'collapsed'
          }
        >
          <th>
            <SectionNameContainer>
              <SectionToggle
                onClick={() => setIsOpen(!isOpen)}
                className={getClassName}
              />
              <SectionName color={section.font_color} font={section.font}>
                {section.name}
              </SectionName>
            </SectionNameContainer>
          </th>
          {products.map(p => (
            <td key={p.uuid} />
          ))}
        </CollapsibleRow>
        {section.subsections.map((sub, idx) => (
          <React.Fragment key={sub.uuid}>
            {sub.name && (
              <CollapsibleRow
                className={
                  isOpen &&
                  shouldDisplaySubsection(sub, products, differencesOnly)
                    ? ''
                    : 'collapsed'
                }
                border={idx > 0}
              >
                <th>
                  <SubsectionWrapper border={idx > 0}>
                    <SubsectionName color={sub.font_color} font={sub.font}>
                      {sub.name}
                    </SubsectionName>
                  </SubsectionWrapper>
                </th>
                {products.map(p => (
                  <td key={p.uuid}>
                    <div />
                  </td>
                ))}
              </CollapsibleRow>
            )}

            {sub.features
              .filter(f => !f.only_for_mob_exp_display)
              .map((f, fidx) => (
                <Feature
                  feature={f}
                  products={products}
                  className={getClassName}
                  key={f.uuid}
                  differencesOnly={differencesOnly}
                  yesLabel={yesLabel}
                  border={sub.name === null || fidx > 0}
                  footerKeys={footerKeys}
                />
              ))}
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  );
};

const SubsectionWrapper = styled.div`
  box-shadow: ${({ border }) => (border ? `0 -2px ${colors.grey7}` : 'none')};
  padding-left: ${({ theme }) => theme.webRT.getWidthValue(16)}px;
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(166)}px;
`;

const SectionToggle = styled.button`
  vertical-align: middle;
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(107)}px;
  margin-right: ${({ theme }) => theme.webRT.getWidthValue(24)}px;
  height: ${({ theme }) => theme.webRT.getWidthValue(35)}px;
  width: ${({ theme }) => theme.webRT.getWidthValue(35)}px;
  background: url(${DropdownClose}) no-repeat center center;
  background-size: ${({ theme }) => theme.webRT.getWidthValue(35)}px
    ${({ theme }) => theme.webRT.getWidthValue(35)}px;
  border: none;
  margin-bottom: ${({ theme }) => theme.webRT.getWidthValue(5)}px;
  &.collapsed {
    background: url(${DropdownOpen}) no-repeat center center;
    background-size: ${({ theme }) => theme.webRT.getWidthValue(35)}px
      ${({ theme }) => theme.webRT.getWidthValue(35)}px;
  }
`;

const SectionNameContainer = styled.div`
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(16)}px;
  margin-bottom: ${({ theme }) => theme.webRT.getWidthValue(22)}px;
  display: flex;
`;

const SectionName = styled.h2`
  font-family: ${({ font }) => font || families.primaryFont};
  font-size: ${({ theme }) => theme.webRT.getFontSize(41)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(41)}px;
  color: ${({ color }) => color};
  font-weight: ${weights.normal};
  margin: 0 0 5px;
`;

const SubsectionName = styled.h3`
  font-family: ${({ font }) => font || families.primaryFont};
  color: ${({ color }) => color};
  font-size: ${({ theme }) => theme.webRT.getFontSize(28)}px;
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(15)}px;
  margin-bottom: ${({ theme }) => theme.webRT.getWidthValue(29)}px;
  line-height: normal;
  text-align: left;
`;

const CollapsibleRow = styled.tr`
  &.collapsed {
    div {
      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  td {
    box-shadow: ${({ border }) => (border ? `0 -2px ${colors.grey7}` : 'none')};
    :last-of-type {
      box-shadow: ${({ border, theme }) =>
        border
          ? `${theme.webRT.getWidthValue(-13)}px 0px ${colors.white},
        ${theme.webRT.getWidthValue(-13)}px -2px ${colors.grey7}`
          : 'none'};
    }
    div {
      box-shadow: ${({ border }) =>
        border ? `0 -2px ${colors.grey7}` : 'none'};
    }
  }
  td,
  th {
    div {
      max-height: 500px;
      overflow: hidden;
      transition: max-height 0.3s, margin 0.5s;
    }
  }
`;

Section.propTypes = {
  products: PropTypes.arrayOf(types.PRODUCT_PROP_TYPES).isRequired,
  section: types.SECTION_PROP_TYPES.isRequired,
  differencesOnly: PropTypes.bool.isRequired,
  yesLabel: PropTypes.string.isRequired,
  showShopSelectContainer: PropTypes.bool,
  footerKeys: PropTypes.arrayOf(PropTypes.string),
};

Section.defaultProps = {
  showShopSelectContainer: false,
  footerKeys: [],
};

export default Section;
