export const getValue = (feature, product) => {
  const productFeature = product.product_features.find(
    pf => pf.feature === feature.uuid
  );
  if (productFeature === undefined) {
    return null;
  }
  if (productFeature.selected_option !== null) {
    return productFeature.selected_option;
  }
  return productFeature.feature;
};

export const shouldDisplayFeature = (feature, products, toggle) => {
  if (!toggle || products.length < 2) {
    return true;
  }
  const value = getValue(feature, products[0]);
  return products.some(p => value !== getValue(feature, p));
};

export const shouldDisplaySubsection = (subsection, products, toggle) => {
  if (!toggle || products.length < 2) {
    return true;
  }
  return subsection.features.some(f =>
    shouldDisplayFeature(f, products, toggle)
  );
};

export const shouldDisplaySection = (section, products, toggle) => {
  if (!toggle || products.length < 2) {
    return true;
  }
  return section.subsections.some(sub =>
    shouldDisplaySubsection(sub, products, toggle)
  );
};
