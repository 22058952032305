import React, { useState, useEffect } from 'react';
import { useCompData } from '../../hooks';

export const connect = WrappedComponent => props => {
  const [currentCustomizations, setCurrentCustomizations] = useState({});
  const compData = useCompData();

  useEffect(() => {
    if (compData.chartData.comparison_chart && compData.chartData.labels) {
      const c = compData.chartData.comparison_chart;
      const { labels } = compData.chartData;
      const custom = {
        title: c?.header,
        desktopColumnColor: c.desktop_column_color,
        pickerBackgroundColor: c.picker_background_color || '#f6f7fa',
        buttonColor: c.button_color,
        buttonHoverColor: c.button_hover_color,
        desktopChartBackgroundColor: c.desktop_chart_background_color || '#FFF',
        shopLabel:
          c.shop_button_copy || labels.find(l => l.name === 'shop')?.text,
        exploreLabel: labels.find(l => l.name === 'explore')?.text,
        differencesLabel: labels.find(l => l.name === 'show_only_differences')
          ?.text,
        yesLabel: labels.find(l => l.name === 'yes')?.text,
        showPrice: c.show_price,
        showProductSubtitle: c.show_product_description, // TODO: normalize this property
        shopButtonPrimaryColor: c.shop_button_primary_color || '#447355',
        shopButtonPrimaryBorderColor:
          c.shop_button_primary_border_color || '#447355',
        shopButtonTextPrimaryColor:
          c.shop_button_text_primary_color || '#FFFFFF',
        shopButtonTextHoverColor:
          c.shop_button_text_primary_border_color || '#FFFFFF',
        shopButtonHoverColor: c.shop_button_hover_color || '#518A70',
        shopButtonHoverBorderColor:
          c.shop_button_hover_border_color || '#518A70',
        shopSelectButtonPrimaryColor:
          c.shop_select_button_primary_color || '#447355',
        shopSelectButtonPrimaryBorderColor:
          c.shop_select_button_primary_border_color || '#447355',
        shopSelectButtonTextPrimaryColor:
          c.shop_select_button_text_primary_color || '#FFFFFF',
        shopSelectButtonTextHoverColor:
          c.shop_select_button_text_hover_color || '#FFFFFF',
        shopSelectButtonHoverColor:
          c.shop_select_button_hover_color || '#518A70',
        shopSelectButtonHoverBorderColor:
          c.shop_select_button_hover_border_color || '#518A70',
      };

      setCurrentCustomizations(custom);
    }
    return () => {};
  }, [compData]);

  return (
    <WrappedComponent
      compData={compData}
      {...currentCustomizations}
      {...props}
    />
  );
};

export default connect;
