import axios from 'axios';

export const fetchCompChartDynamicData = (baseUrl, id) =>
  axios.get(
    `${baseUrl}/external/comparison_chart_partners/${id}?resource_type=comparison_chart`
  );

export const fetchCompChartStaticData = (baseUrl, id) =>
  axios.get(
    `${baseUrl}/external/static_resources/${id}?resource_type=comparison_chart`
  );

export const fetchCompMock = (baseUrl, UUID) =>
  axios.get('./mockResponse.json', {
    params: {
      baseUrl,
      UUID,
    },
  });

export default fetchCompMock;
