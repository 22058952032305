import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';
import CONST from '../../data/constants';

const { H_LENGTHS } = CONST;

const Horizontal = ({ bgColor, lineColor, length, thick }) => (
  <Wrapper>
    <Line
      className={length}
      bgColor={bgColor}
      lineColor={lineColor}
      thick={thick}
    />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
`;
const Line = styled.div`
  margin: 0 auto;
  border-bottom: ${({ thick }) => thick}px solid ${({ lineColor }) => lineColor};
  width: 100%;
  &.${H_LENGTHS.HALF} {
    width: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(125)
        : theme.webRT.getWidthValue(125)}px;
  }
  &.${H_LENGTHS.PARTIAL} {
    width: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(320)
        : theme.webRT.getWidthValue(125)}px;
  }
`;

Horizontal.propTypes = {
  bgColor: PropTypes.string,
  lineColor: PropTypes.string,
  length: PropTypes.string,
  thick: PropTypes.number,
};

Horizontal.defaultProps = {
  bgColor: colors.grey5,
  lineColor: colors.grey1,
  length: H_LENGTHS.FULL,
  thick: 1,
};

export default Horizontal;
