import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ScrollSyncNode } from 'scroll-sync-react';
import Toggle from '../../Toggle';
import types from '../../../utils/CustomPropTypes';
import { colors, fontVariables } from '../../../styles/variables';
import Separator from '../../Separator';
import CONST from '../../../data/constants';
import analytics from '../../../utils/analytics';
import Image from '../../Image';

const { weights, families } = fontVariables;
const { H_LENGTHS } = CONST;

const ariaLabel = '';

const DesktopProductColumn = ({
  products,
  shopLabel,
  exploreLabel,
  showPrice,
  showProductSubtitle,
  differencesOnly,
  handleToggle,
  differencesLabel,
  showShopSelectContainer,
  buttonColor,
  shopButtonPrimaryColor,
  shopButtonPrimaryBorderColor,
  shopButtonTextPrimaryColor,
  shopButtonTextHoverColor,
  shopButtonHoverColor,
  shopButtonHoverBorderColor,
  shopSelectButtonPrimaryColor,
  shopSelectButtonPrimaryBorderColor,
  shopSelectButtonTextPrimaryColor,
  shopSelectButtonTextHoverColor,
  shopSelectButtonHoverColor,
  shopSelectButtonHoverBorderColor,
}) => {
  // Row Constructor
  // Each row in the header is an individual table to manage exact same styling, spacing and sticky behavior
  const Row = (stick, content, z) => (
    <ScrollSyncNode>
      <TopWrapper stick={stick} z={z}>
        <table>
          <thead>
            <tr>{content}</tr>
          </thead>
        </table>
      </TopWrapper>
    </ScrollSyncNode>
  );
  const ImgRow = Row(
    0,
    <>
      <ToggleHead aria-label={ariaLabel}>
        <Toggle
          defaultChecked={differencesOnly}
          handleToggle={handleToggle}
          color={buttonColor}
          label={differencesLabel}
        />
      </ToggleHead>
      {products.map(p => (
        <ImgTd key={p.uuid}>
          <div className="white">
            <ImgWrap>
              <ProdImg src={p.image_url} alt={p.name} />
            </ImgWrap>
          </div>
        </ImgTd>
      ))}
    </>,
    3
  );
  const NameRow = Row(
    164,
    <>
      <th aria-label={ariaLabel} />
      {products.map(p => (
        <td key={p.uuid}>
          <Name
            dangerouslySetInnerHTML={{ __html: p.name }}
            hasPaddingBottom={!p.link}
          />
        </td>
      ))}
    </>,
    3
  );
  const DescriptionRow = Row(
    null,
    <>
      <th aria-label={ariaLabel} />
      {products.map(p => (
        <td key={p.uuid}>
          <Description>{p.description}</Description>
        </td>
      ))}
    </>
  );
  const PriceRow = Row(
    188,
    <>
      <th aria-label={ariaLabel} />
      {products.map(p => (
        <td key={p.uuid}>{p.price && <Price>{p.price}</Price>}</td>
      ))}
    </>
  );
  const ShopRow = Row(
    null,
    <>
      <th aria-label={ariaLabel} />
      {products.map(p => (
        <td key={p.uuid}>
          <div>
            {p.link && (
              <Button
                href={p.link}
                target="_blank"
                rel="noreferrer"
                onClick={() => analytics.trackClickShop(p)}
                showShopSelectContainer={showShopSelectContainer}
                buttonPrimaryColor={shopButtonPrimaryColor}
                buttonPrimaryBorderColor={shopButtonPrimaryBorderColor}
                buttonTextPrimaryColor={shopButtonTextPrimaryColor}
                buttonTextHoverColor={shopButtonTextHoverColor}
                buttonHoverColor={shopButtonHoverColor}
                buttonHoverBorderColor={shopButtonHoverBorderColor}
              >
                {shopLabel}
              </Button>
            )}
          </div>
        </td>
      ))}
    </>
  );
  const DivisorRow = Row(
    null,
    <>
      <th aria-label={ariaLabel} />
      {products.map(p =>
        p.display_explore ? (
          <td key={p.uuid}>
            <Divisor>
              <Separator.Horizontal length={H_LENGTHS.HALF} />
            </Divisor>
          </td>
        ) : (
          <td key={p.uuid}>
            <div>
              <Empty />
            </div>
          </td>
        )
      )}
    </>
  );

  const PaymentsRow = Row(
    null,
    <>
      <th aria-label={ariaLabel} />
      {products.map(p =>
        p.display_explore ? (
          <td key={p.uuid}>
            <Payments>{p.explore_copy}</Payments>
          </td>
        ) : (
          <td key={p.uuid}>
            <div>
              <Empty />
            </div>
          </td>
        )
      )}
    </>
  );
  const ExploreRow = Row(
    null,
    <>
      <th aria-label={ariaLabel} />
      {products.map(p =>
        p.display_explore ? (
          <td key={p.uuid}>
            {p.explore_link && (
              <Explore>
                <Button
                  href={p?.explore_link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => analytics.trackClickShop(p, true)}
                  buttonPrimaryColor={shopSelectButtonPrimaryColor}
                  buttonPrimaryBorderColor={shopSelectButtonPrimaryBorderColor}
                  buttonTextPrimaryColor={shopSelectButtonTextPrimaryColor}
                  buttonTextHoverColor={shopSelectButtonTextHoverColor}
                  buttonHoverColor={shopSelectButtonHoverColor}
                  buttonHoverBorderColor={shopSelectButtonHoverBorderColor}
                >
                  {p.shop_select_button_copy || exploreLabel}
                </Button>
              </Explore>
            )}
          </td>
        ) : (
          <td key={p.uuid}>
            <div>
              <Empty />
            </div>
          </td>
        )
      )}
    </>
  );
  const EmptyRow = Row(
    showPrice ? 248 : 198,
    <>
      <th aria-label={ariaLabel} />
      {products.map(p => (
        <td key={p.uuid}>
          <div>
            <Empty />
          </div>
        </td>
      ))}
    </>
  );

  return (
    <>
      {ImgRow}
      {NameRow}
      {showProductSubtitle && DescriptionRow}
      {showPrice && PriceRow}
      {ShopRow}
      {showShopSelectContainer && (
        <>
          {DivisorRow}
          {PaymentsRow}
          {ExploreRow}
          {EmptyRow}
        </>
      )}
    </>
  );
};

const ToggleHead = styled.th`
  text-align: left;
  vertical-align: bottom;
  label {
    padding-left: ${({ theme }) => theme.webRT.getWidthValue(182)}px;
    padding-bottom: ${({ theme }) => theme.webRT.getWidthValue(5)}px;
  }
  span {
    margin-left: ${({ theme }) => theme.webRT.getWidthValue(15)}px;
  }
`;

const ImgTd = styled.td`
  z-index: 3;
`;

const ProdImg = styled(Image)`
  height: ${({ theme }) => theme.webRT.getWidthValue(147)}px;
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(20)}px;
`;
const ImgWrap = styled.div`
  background: ${colors.green3};
  border-radius: 20px 20px 0 0;
`;

const Name = styled.div`
  font-size: ${({ theme }) => theme.webRT.getFontSize(30)}px;
  font-weight: ${weights.bold};
  letter-spacing: 0.3px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(38)}px;
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(9)}px;
  padding-bottom: ${({ theme, hasPaddingBottom }) =>
    hasPaddingBottom ? theme.webRT.getWidthValue(18) : 0}px;
  font-family: ${families.primaryFont};
  sup {
    top: ${({ theme }) => theme.webRT.getWidthValue(9)}px;
    vertical-align: super;
  }
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.webRT.getFontSize(20)}px;
  font-weight: ${weights.bold};
  letter-spacing: 0.2px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(24)}px;
  color: ${colors.grey1};
  padding: ${({ theme }) => theme.webRT.getWidthValue(7)}px
    ${({ theme }) => theme.webRT.getWidthValue(20)}px 0;
`;

const Price = styled.div`
  font-size: ${({ theme }) => theme.webRT.getFontSize(18)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(18)}px;
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(27)}px;
  color: ${colors.grey1};
  padding-bottom: ${({ theme }) => theme.webRT.getWidthValue(17)}px;
  font-family: ${families.primaryFont};
`;

const TopWrapper = styled.div`
  position: ${({ stick }) => (stick == null ? 'inherit' : 'sticky')};
  top: ${({ stick, theme }) => theme.webRT.getWidthValue(stick) || 0}px;
  z-index: ${({ z }) => z || 2};
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Payments = styled.div`
  font-size: ${({ theme }) => theme.webRT.getFontSize(18)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(20)}px;
  color: ${colors.grey1};
  padding: ${({ theme }) => theme.webRT.getWidthValue(25)}px
    ${({ theme }) => theme.webRT.getWidthValue(35)}px 0;
  font-family: ${families.primaryFont};
`;

const Divisor = styled.div`
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(23)}px;
`;

const Explore = styled.div`
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(14)}px;
  padding-bottom: ${({ theme }) => theme.webRT.getWidthValue(38)}px;
`;

const Empty = styled.div`
  height: ${({ theme }) => theme.webRT.getWidthValue(15)}px;
`;

const Button = styled.a`
  display: inline-block;
  font-family: ${families.primaryFont};
  height: ${({ theme }) => theme.webRT.getWidthValue(46)}px;
  width: ${({ theme }) => theme.webRT.getWidthValue(124)}px;
  font-size: ${({ theme }) => theme.webRT.getFontSize(17)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(46)}px;
  letter-spacing: 0.17px;
  font-weight: ${weights.semibold};
  color: ${({ buttonTextPrimaryColor }) => buttonTextPrimaryColor};
  border-radius: ${({ theme }) => theme.webRT.getWidthValue(28)}px;
  background-color: ${({ buttonPrimaryColor }) => buttonPrimaryColor};
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(18)}px;
  margin-bottom: ${({ theme, showShopSelectContainer }) =>
    !showShopSelectContainer ? `${theme.webRT.getWidthValue(18)}px` : 0};
  border: 1px solid;
  border-color: ${({ buttonPrimaryBorderColor }) => buttonPrimaryBorderColor};
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
  :active,
  :focus,
  :visited {
    color: ${({ buttonTextPrimaryColor }) => buttonTextPrimaryColor};
  }
  :hover {
    background-color: ${({ buttonHoverColor }) => buttonHoverColor};
    color: ${({ buttonTextHoverColor }) => buttonTextHoverColor};
    border-color: ${({ buttonHoverBorderColor }) => buttonHoverBorderColor};
  }
`;

DesktopProductColumn.propTypes = {
  products: PropTypes.arrayOf(types.PRODUCT_PROP_TYPES),
  shopLabel: PropTypes.string.isRequired,
  exploreLabel: PropTypes.string.isRequired,
  differencesLabel: PropTypes.string.isRequired,
  showPrice: PropTypes.bool.isRequired,
  showProductSubtitle: PropTypes.bool.isRequired,
  differencesOnly: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  showShopSelectContainer: PropTypes.bool,
  shopButtonPrimaryColor: PropTypes.string.isRequired,
  shopButtonPrimaryBorderColor: PropTypes.string.isRequired,
  shopButtonTextPrimaryColor: PropTypes.string.isRequired,
  shopButtonTextHoverColor: PropTypes.string.isRequired,
  shopButtonHoverColor: PropTypes.string.isRequired,
  shopButtonHoverBorderColor: PropTypes.string.isRequired,
  shopSelectButtonPrimaryColor: PropTypes.string.isRequired,
  shopSelectButtonPrimaryBorderColor: PropTypes.string.isRequired,
  shopSelectButtonTextPrimaryColor: PropTypes.string.isRequired,
  shopSelectButtonTextHoverColor: PropTypes.string.isRequired,
  shopSelectButtonHoverColor: PropTypes.string.isRequired,
  shopSelectButtonHoverBorderColor: PropTypes.string.isRequired,
};

DesktopProductColumn.defaultProps = {
  products: [],
  showShopSelectContainer: false,
};
export default DesktopProductColumn;
