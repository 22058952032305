import PropTypes from 'prop-types';

const SUBSECTION_PROP_TYPES = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string,
});

SUBSECTION_PROP_TYPES.defaultProps = {
  name: '',
};

export default SUBSECTION_PROP_TYPES;
