import React from 'react';
import styled from 'styled-components';

// TODO: make copy translatable

const ErrorMessage = () => (
  <Container>
    <Title>Something went wrong! Please try again.</Title>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(300)
      : theme.webRT.getWidthValue(300)}px; ;
`;

const Title = styled.h2`
  text-align: center;
`;

export default ErrorMessage;
