import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProductColumn from '../../ProductColumn';
import Section from './Section';
import types from '../../../utils/CustomPropTypes';
import Toggle from '../../Toggle';
import { colors, fontVariables } from '../../../styles/variables';
import analytics from '../../../utils/analytics';
import { getLegalCopies } from '../../../utils/LegalCopiesUtils/LegalCopiesUtils';

const { families } = fontVariables;

const MobileFeatureTable = ({
  leftProduct,
  rightProduct,
  pickerBackgroundColor,
  shopLabel,
  exploreLabel,
  differencesLabel,
  sections,
  showPrice,
  showProductSubtitle,
  buttonColor,
  showShopSelectContainer,
  legalCopies,
  shopButtonPrimaryColor,
  shopButtonPrimaryBorderColor,
  shopButtonTextPrimaryColor,
  shopSelectButtonPrimaryColor,
  shopSelectButtonPrimaryBorderColor,
  shopSelectButtonTextPrimaryColor,
}) => {
  const [differencesOnly, setDifferencesOnly] = useState(false);
  const handleToggle = () => {
    setDifferencesOnly(!differencesOnly);
    analytics.trackShowOnlyDifferences(!differencesOnly);
  };
  const footerCopies = getLegalCopies(
    [leftProduct, rightProduct],
    sections,
    legalCopies,
    differencesOnly
  );
  return (
    <Container>
      <Row>
        <Toggle
          defaultChecked={differencesOnly}
          handleToggle={handleToggle}
          color={buttonColor}
          label={differencesLabel}
        />
      </Row>

      <ProductColumn.MobileProductColumn
        leftProduct={leftProduct}
        rightProduct={rightProduct}
        pickerBackgroundColor={pickerBackgroundColor}
        shopLabel={shopLabel}
        exploreLabel={exploreLabel}
        showPrice={showPrice}
        showProductSubtitle={showProductSubtitle}
        showShopSelectContainer={showShopSelectContainer}
        shopButtonPrimaryColor={shopButtonPrimaryColor}
        shopButtonPrimaryBorderColor={shopButtonPrimaryBorderColor}
        shopButtonTextPrimaryColor={shopButtonTextPrimaryColor}
        shopSelectButtonPrimaryColor={shopSelectButtonPrimaryColor}
        shopSelectButtonPrimaryBorderColor={shopSelectButtonPrimaryBorderColor}
        shopSelectButtonTextPrimaryColor={shopSelectButtonTextPrimaryColor}
      />
      {sections.map(section => (
        <Section
          leftProduct={leftProduct}
          rightProduct={rightProduct}
          section={section}
          key={section.uuid}
          differencesOnly={differencesOnly}
          showShopSelectContainer={showShopSelectContainer}
          footerKeys={[...footerCopies.keys()]}
        />
      ))}
      <LegalCopyContainer>
        {[...footerCopies.keys()].map((key, idx) => (
          <LegalCopy key={key}>
            <sup>{idx + 1}</sup>
            {footerCopies.get(key)}
          </LegalCopy>
        ))}
      </LegalCopyContainer>
    </Container>
  );
};
const Container = styled.div``;

const Row = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  padding-left: ${({ theme }) => theme.mobileRT.getWidthValue(28)}px;
  padding-right: ${({ theme }) => theme.mobileRT.getWidthValue(28)}px;
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(11)}px;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(11)}px;
  span {
    margin-left: ${({ theme }) => theme.mobileRT.getWidthValue(14)}px;
    padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(3)}px;
  }
  border-top: 1px solid ${colors.grey1};
  border-bottom: 1px solid ${colors.grey1};
`;

const LegalCopyContainer = styled.div`
  padding: 0 ${({ theme }) => theme.mobileRT.getWidthValue(27)}px;
  margin-top: ${({ theme }) => theme.mobileRT.getWidthValue(48)}px;
`;

const LegalCopy = styled.p`
  margin: 0;
  color: ${colors.grey1};
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(8)}px;
  font-family: ${families.primaryFont};
`;

MobileFeatureTable.propTypes = {
  leftProduct: types.PRODUCT_PROP_TYPES.isRequired,
  rightProduct: types.PRODUCT_PROP_TYPES.isRequired,
  legalCopies: PropTypes.arrayOf(types.LEGALCOPY_PROP_TYPES),
  sections: PropTypes.arrayOf(types.SECTION_PROP_TYPES).isRequired,
  pickerBackgroundColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  shopLabel: PropTypes.string.isRequired,
  differencesLabel: PropTypes.string.isRequired,
  exploreLabel: PropTypes.string.isRequired,
  showPrice: PropTypes.bool.isRequired,
  showProductSubtitle: PropTypes.bool.isRequired,
  showShopSelectContainer: PropTypes.bool,
  shopButtonPrimaryColor: PropTypes.string.isRequired,
  shopButtonPrimaryBorderColor: PropTypes.string.isRequired,
  shopButtonTextPrimaryColor: PropTypes.string.isRequired,
  shopSelectButtonPrimaryColor: PropTypes.string.isRequired,
  shopSelectButtonPrimaryBorderColor: PropTypes.string.isRequired,
  shopSelectButtonTextPrimaryColor: PropTypes.string.isRequired,
};

MobileFeatureTable.defaultProps = {
  showShopSelectContainer: false,
  legalCopies: [],
};

export default MobileFeatureTable;
