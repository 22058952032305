import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import ReactTooltip from 'react-tooltip';
import { scroller } from 'react-scroll';
import ProductColumn from '../../ProductColumn';
import CustomPropTypes from '../../../utils/CustomPropTypes';
import Section from './Section';
import { colors, fontVariables } from '../../../styles/variables';
import analytics from '../../../utils/analytics';
import { getLegalCopies } from '../../../utils/LegalCopiesUtils/LegalCopiesUtils';

const { weights, families } = fontVariables;
const CloseIcon =
  'https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/Close.svg';

const DesktopFeatureTable = ({
  products,
  desktopColumnColor,
  desktopChartBackgroundColor,
  shopLabel,
  exploreLabel,
  differencesLabel,
  sections,
  showPrice,
  showProductSubtitle,
  yesLabel,
  showShopSelectContainer,
  legalCopies,
  buttonColor,
  buttonHoverColor,
  shopButtonPrimaryColor,
  shopButtonPrimaryBorderColor,
  shopButtonTextPrimaryColor,
  shopButtonTextHoverColor,
  shopButtonHoverColor,
  shopButtonHoverBorderColor,
  shopSelectButtonPrimaryColor,
  shopSelectButtonPrimaryBorderColor,
  shopSelectButtonTextPrimaryColor,
  shopSelectButtonTextHoverColor,
  shopSelectButtonHoverColor,
  shopSelectButtonHoverBorderColor,
}) => {
  const tooltip = useRef(null);
  const [differencesOnly, setDifferencesOnly] = useState(false);

  const handleToggle = () => {
    scroller.scrollTo('comp-chart-anchor', {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
    analytics.trackShowOnlyDifferences(!differencesOnly);
    setDifferencesOnly(!differencesOnly);
  };

  const footerCopies = getLegalCopies(
    products,
    sections,
    legalCopies,
    differencesOnly
  );

  return (
    <Container
      desktopColumnColor={desktopColumnColor}
      desktopChartBackgroundColor={desktopChartBackgroundColor}
    >
      <ScrollSync>
        <>
          <ProductColumn.DesktopProductColumn
            products={products}
            shopLabel={shopLabel}
            exploreLabel={exploreLabel}
            showPrice={showPrice}
            showProductSubtitle={showProductSubtitle}
            differencesOnly={differencesOnly}
            handleToggle={handleToggle}
            buttonColor={buttonColor}
            differencesLabel={differencesLabel}
            showShopSelectContainer={showShopSelectContainer}
            buttonHoverColor={buttonHoverColor}
            shopButtonPrimaryColor={shopButtonPrimaryColor}
            shopButtonPrimaryBorderColor={shopButtonPrimaryBorderColor}
            shopButtonTextPrimaryColor={shopButtonTextPrimaryColor}
            shopButtonTextHoverColor={shopButtonTextHoverColor}
            shopButtonHoverColor={shopButtonHoverColor}
            shopButtonHoverBorderColor={shopButtonHoverBorderColor}
            shopSelectButtonPrimaryColor={shopSelectButtonPrimaryColor}
            shopSelectButtonPrimaryBorderColor={
              shopSelectButtonPrimaryBorderColor
            }
            shopSelectButtonTextPrimaryColor={shopSelectButtonTextPrimaryColor}
            shopSelectButtonTextHoverColor={shopSelectButtonTextHoverColor}
            shopSelectButtonHoverColor={shopSelectButtonHoverColor}
            shopSelectButtonHoverBorderColor={shopSelectButtonHoverBorderColor}
          />
          <ScrollSyncNode>
            <TableWrap>
              <table cellSpacing="0" border="0" cellPadding="0">
                <tbody>
                  {sections.map(s => (
                    <Section
                      section={s}
                      products={products}
                      key={s.uuid}
                      differencesOnly={differencesOnly}
                      yesLabel={yesLabel}
                      showShopSelectContainer={showShopSelectContainer}
                      footerKeys={[...footerCopies.keys()]}
                    />
                  ))}
                </tbody>
              </table>
            </TableWrap>
          </ScrollSyncNode>
        </>
      </ScrollSync>
      <StyledTooltip
        clickable
        effect="solid"
        event="click"
        place="right"
        backgroundColor={colors.grey1}
        ref={tooltip}
        getContent={data => (
          <>
            <TooltipClose
              onClick={() => {
                const { current } = tooltip;
                current.tooltipRef = null;
                ReactTooltip.hide();
              }}
            >
              <img src={CloseIcon} alt="Close" />
            </TooltipClose>
            <TooltipCopy dangerouslySetInnerHTML={{ __html: data }} />
          </>
        )}
      />
      <LegalCopyContainer>
        {[...footerCopies.keys()].map((key, idx) => (
          <LegalCopy key={key}>
            <sup>{idx + 1}</sup>
            {footerCopies.get(key)}
          </LegalCopy>
        ))}
      </LegalCopyContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(68)}px;
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
  }
  td,
  th {
    padding: 0;
    width: ${({ theme }) => theme.webRT.getWidthValue(258)}px;
    height: 100%;
    background: ${colors.white};
    border: 0;
  }
  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  td {
    text-align: center;
    > div {
      margin: 0 ${({ theme }) => theme.webRT.getWidthValue(13)}px;
      height: 100%;
      background: ${({ desktopColumnColor }) => desktopColumnColor};
    }
    div.white {
      background: ${({ desktopChartBackgroundColor }) =>
        desktopChartBackgroundColor};
    }
  }

  tbody th {
    text-align: left;
    position: relative;
    width: ${({ theme }) => theme.webRT.getWidthValue(765)}px;
  }
  thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    width: ${({ theme }) => theme.webRT.getWidthValue(765)}px;
    background: ${({ desktopChartBackgroundColor }) =>
      desktopChartBackgroundColor};
  }
  tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
`;

const TableWrap = styled.div`
  overflow-x: scroll;
  overscroll-behavior-x: none;
`;

const StyledTooltip = styled(ReactTooltip)`
  &.__react_component_tooltip.show {
    opacity: 1;
    background: ${colors.grey1};
    color: ${colors.white};
    font-weight: ${weights.normal};
    max-width: ${({ theme }) => theme.webRT.getWidthValue(506)}px;
    width: ${({ theme }) => theme.webRT.getWidthValue(506)}px;
    font-size: ${({ theme }) => theme.webRT.getFontSize(18)}px;
    line-height: ${({ theme }) => theme.webRT.getFontSize(22)}px;
    border-radius: 11px;
    padding: 0;
  }
  &.place-right::before {
    display: none;
  }
`;
const TooltipClose = styled.div`
  height: ${({ theme }) => theme.webRT.getWidthValue(30)}px;
  width: ${({ theme }) => theme.webRT.getWidthValue(30)}px;
  img {
    height: 100%;
  }
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(15)}px;
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(15)}px;
  flex-shrink: 0;
  cursor: pointer;
`;
const TooltipCopy = styled.div`
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(14)}px;
  padding-bottom: ${({ theme }) => theme.webRT.getWidthValue(45)}px;
  padding-right: ${({ theme }) => theme.webRT.getWidthValue(25)}px;
  padding-left: ${({ theme }) => theme.webRT.getWidthValue(23)}px;
  text-align: left;
`;

const LegalCopyContainer = styled.div`
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(144)}px;
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(100)}px;
`;

const LegalCopy = styled.p`
  color: ${colors.grey1};
  font-size: ${({ theme }) => theme.webRT.getFontSize(12)}px;
  line-height: 2.25;
  font-family: ${families.primaryFont};
  margin: 0;
`;

DesktopFeatureTable.propTypes = {
  products: PropTypes.arrayOf(CustomPropTypes.PRODUCT_PROP_TYPES),
  legalCopies: PropTypes.arrayOf(CustomPropTypes.LEGALCOPY_PROP_TYPES),
  desktopColumnColor: PropTypes.string.isRequired,
  desktopChartBackgroundColor: PropTypes.string.isRequired,
  shopLabel: PropTypes.string.isRequired,
  exploreLabel: PropTypes.string.isRequired,
  differencesLabel: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(CustomPropTypes.SECTION_PROP_TYPES),
  showPrice: PropTypes.bool.isRequired,
  showProductSubtitle: PropTypes.bool.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonHoverColor: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  showShopSelectContainer: PropTypes.bool,
  shopButtonPrimaryColor: PropTypes.string.isRequired,
  shopButtonPrimaryBorderColor: PropTypes.string.isRequired,
  shopButtonTextPrimaryColor: PropTypes.string.isRequired,
  shopButtonTextHoverColor: PropTypes.string.isRequired,
  shopButtonHoverColor: PropTypes.string.isRequired,
  shopButtonHoverBorderColor: PropTypes.string.isRequired,
  shopSelectButtonPrimaryColor: PropTypes.string.isRequired,
  shopSelectButtonPrimaryBorderColor: PropTypes.string.isRequired,
  shopSelectButtonTextPrimaryColor: PropTypes.string.isRequired,
  shopSelectButtonTextHoverColor: PropTypes.string.isRequired,
  shopSelectButtonHoverColor: PropTypes.string.isRequired,
  shopSelectButtonHoverBorderColor: PropTypes.string.isRequired,
};

DesktopFeatureTable.defaultProps = {
  products: [],
  sections: [],
  legalCopies: [],
  showShopSelectContainer: false,
};

export default DesktopFeatureTable;
