import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CustomPropTypes from '../../../../utils/CustomPropTypes';
import { fontVariables } from '../../../../styles/variables';
import CarouselX from '../../../../assets/images/carouselX@2x.png';
import Image from '../../../Image';

const { weights, families } = fontVariables;

const ProductSlide = ({
  product,
  updateProduct,
  buttonColor,
  pickerBackgroundColor,
  buttonHoverColor,
}) => {
  const handleClick = () => {
    updateProduct(product.uuid);
  };

  const handleRemove = () => {
    updateProduct(product.uuid);
  };

  return (
    <Container>
      <Border
        onClick={handleClick}
        active={product.selected}
        border={buttonColor}
        background={pickerBackgroundColor}
      >
        {product.selected && (
          <RemoveButton
            onClick={handleRemove}
            color={buttonColor}
            hoverColor={buttonHoverColor}
          >
            Remove
          </RemoveButton>
        )}
        <ProductImage>
          <Image src={product.image_url} alt={product.name} />
        </ProductImage>
        <Title dangerouslySetInnerHTML={{ __html: product.name }} />
        <SubTitle>{product.product_kind}</SubTitle>
      </Border>
    </Container>
  );
};

const Container = styled.div`
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(15)}px;
  position: relative;
  text-align: center;
`;
const Border = styled.div`
  width: ${({ theme }) => theme.webRT.getWidthValue(258)}px;
  height: ${({ theme }) => theme.webRT.getWidthValue(342)}px;
  border-radius: 22px;
  border: 5px solid
    ${({ active, border, background }) => (active ? border : background)};
  margin: auto;
  cursor: pointer;
  transition: all 0.15s linear;
`;

const RemoveButton = styled.button`
  opacity: 1;
  height: ${({ theme }) => theme.webRT.getWidthValue(43)}px;
  width: ${({ theme }) => theme.webRT.getWidthValue(43)}px;
  border-radius: 50%;
  z-index: 99;
  transform: rotate(0) translateY(-100%);
  transition: opacity 0.15s linear;
  background: url(${CarouselX}) no-repeat center center;
  background-color: ${({ color }) => color};
  background-size: ${({ theme }) => theme.webRT.getWidthValue(11)}px
    ${({ theme }) => theme.webRT.getWidthValue(11)}px;
  position: absolute;
  font-size: 0;
  line-height: 0;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  float: right;
  top: ${({ theme }) => theme.webRT.getWidthValue(48)}px;
  right: ${({ theme }) => theme.webRT.getWidthValue(5)}px;
  &:before {
    content: none;
  }
  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.webRT.getFontSize(24)}px;
  font-weight: ${weights.bold};
  line-height: ${({ theme }) => theme.webRT.getFontSize(24)}px;
  letter-spacing: 0.24px;
  margin: 0;
  font-family: ${families.primaryFont};
`;
const SubTitle = styled.h3`
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(8)}px;
  font-size: ${({ theme }) => theme.webRT.getFontSize(17)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(18)}px;
  letter-spacing: 0.17px;
  font-weight: ${weights.normal};
  margin: 0;
  font-family: ${families.primaryFont};
  text-transform: capitalize;
`;

const ProductImage = styled.div`
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(58)}px;
  padding-bottom: ${({ theme }) => theme.webRT.getWidthValue(8)}px;
  img {
    height: ${({ theme }) => theme.webRT.getWidthValue(193)}px;
    margin: 0 auto;
  }
`;

ProductSlide.propTypes = {
  product: CustomPropTypes.PRODUCT_PROP_TYPES.isRequired,
  updateProduct: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  pickerBackgroundColor: PropTypes.string.isRequired,
  buttonHoverColor: PropTypes.string.isRequired,
};

export default ProductSlide;
