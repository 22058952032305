import ResponsiveDimensions from 'responsive-dimensions-web/dist/responsive-dimensions-web';
import checkifMobile from 'is-mobile';
import screenfull from 'screenfull';
import { DESKTOP_DIMENSIONS, MOBILE_DIMENSIONS } from './constants';

export default class ResponsiveTools {
  constructor(containerId = '') {
    this.setDefaults(containerId);
    this.addListeners();
  }

  setDefaults = containerId => {
    this.el = document.getElementById(containerId) || window;
    this.setTools();
  };

  addListeners = () => {
    this.el.addEventListener('resize', this.handleResize.bind(this));
  };

  removeListeners = () => {
    this.el.removeEventListener('resize', this.handleResize.bind(this));
  };

  getBaseValues = () => {
    if (screenfull.isFullscreen) {
      return {
        width: this.el.children[0].offsetWidth,
        height: this.el.children[0].offsetHeight,
      };
    }
    return {
      width: this.el.offsetWidth,
      height: this.el.offsetHeight,
    };
  };

  getDesktopBoardDimensions = () => DESKTOP_DIMENSIONS;

  getMobileBoardDimensions = () => MOBILE_DIMENSIONS;

  getWebResponsiveUtil = () => ({
    getHeightValue: value =>
      ResponsiveDimensions.getHeightValue(
        value,
        this.getDesktopBoardDimensions(),
        this.getBaseValues()
      ),
    getWidthValue: value =>
      ResponsiveDimensions.getWidthValue(
        value,
        this.getDesktopBoardDimensions(),
        this.getBaseValues()
      ),
    getFontSize: value => {
      const size = ResponsiveDimensions.getWidthValue(
        value,
        this.getDesktopBoardDimensions(),
        this.getBaseValues()
      );
      return size >= 10.1 ? size : 10.1;
    },
  });

  getMobileResponsiveUtil = () => ({
    getHeightValue: value =>
      ResponsiveDimensions.getHeightValue(
        value,
        this.getMobileBoardDimensions(),
        this.getBaseValues()
      ),
    getWidthValue: value =>
      ResponsiveDimensions.getWidthValue(
        value,
        this.getMobileBoardDimensions(),
        this.getBaseValues()
      ),
    getFontSize: value => {
      const size = ResponsiveDimensions.getWidthValue(
        value,
        this.getMobileBoardDimensions(),
        this.getBaseValues()
      );
      return size >= 10.1 ? size : 10.1;
    },
  });

  handleResize = () => {
    this.setTools();
  };

  setTools = () => {
    this.isMobile = checkifMobile();
    this.mobileResponsiveUtil = this.getMobileResponsiveUtil();
    this.webResponsiveUtil = this.getWebResponsiveUtil();
  };

  destroy() {
    this.removeListeners();
  }
}
