import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CustomPropTypes from '../../../utils/CustomPropTypes';
import ProductPicker from './ProductPicker';

const MobileProductCarousel = ({
  leftProduct,
  setLeftProduct,
  rightProduct,
  setRightProduct,
  products,
  desktopChartBackgroundColor,
  showShopSelectContainer,
}) => {
  const leftProducts = products.filter(prod => prod.uuid !== rightProduct.uuid);
  const rightProducts = products.filter(prod => prod.uuid !== leftProduct.uuid);

  return (
    <Container bgColor={desktopChartBackgroundColor}>
      {products &&
        leftProduct &&
        rightProduct &&
        [leftProduct, rightProduct].map((product, index) => (
          <ProductPicker
            product={product}
            setProduct={index === 0 ? setLeftProduct : setRightProduct}
            products={index === 0 ? leftProducts : rightProducts}
            key={product?.uuid}
            showShopSelectContainer={showShopSelectContainer}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;
`;

MobileProductCarousel.propTypes = {
  leftProduct: CustomPropTypes.PRODUCT_PROP_TYPES.isRequired,
  rightProduct: CustomPropTypes.PRODUCT_PROP_TYPES.isRequired,
  setLeftProduct: PropTypes.func,
  setRightProduct: PropTypes.func,
  products: PropTypes.arrayOf(CustomPropTypes.PRODUCT_PROP_TYPES),
  desktopChartBackgroundColor: PropTypes.string.isRequired,
  showShopSelectContainer: PropTypes.bool,
};

MobileProductCarousel.defaultProps = {
  setLeftProduct: () => {},
  setRightProduct: () => {},
  products: [],
  showShopSelectContainer: false,
};

export default MobileProductCarousel;
