import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import ProductCarousel from '../../ProductCarousel';
import FeatureTable from '../../FeatureTable';
import types from '../../../utils/CustomPropTypes';
import { colors, fontVariables } from '../../../styles/variables';
import analytics from '../../../utils/analytics';
import Loader from '../../Loader';
import ErrorMessage from '../../ErrorMessage';
import Image from '../../Image';

const { families } = fontVariables;

const MobileWrapper = ({
  compData,
  pickerBackgroundColor,
  shopLabel,
  exploreLabel,
  desktopChartBackgroundColor,
  showPrice,
  showProductSubtitle,
  differencesLabel,
  buttonColor,
  shopButtonPrimaryColor,
  shopButtonPrimaryBorderColor,
  shopButtonTextPrimaryColor,
  shopSelectButtonPrimaryColor,
  shopSelectButtonPrimaryBorderColor,
  shopSelectButtonTextPrimaryColor,
}) => {
  const [leftProduct, setLeftProduct] = useState(null);
  const [rightProduct, setRightProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [sections, setSections] = useState([]);
  const [scrolled, setScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    analytics.trackLoad();
  }, []);

  useEffect(() => {
    if (!isEmpty(compData) && !isEmpty(compData.chartData)) {
      setProducts(compData?.chartData?.comparison_chart?.products);
      setSections(compData?.chartData?.comparison_chart?.sections);
    }
    if (isError !== compData?.isError) {
      setIsError(compData?.isError);
    }
    if (isLoading !== compData?.isLoading) {
      setIsLoading(compData.isLoading);
    }
    return () => {};
  }, [compData, isError, isLoading]);

  useEffect(() => {
    if (products && products.length > 1) {
      const preSelected = products.filter(p => p.mobile_preselected);
      if (preSelected.length === 1) {
        setLeftProduct(preSelected[0]);
        setRightProduct(
          products.filter(product => product.uuid !== preSelected[0].uuid)[0]
        );
      } else if (preSelected.length > 1) {
        setLeftProduct(preSelected[0]);
        setRightProduct(preSelected[1]);
      } else {
        setLeftProduct(products[0]);
        setRightProduct(products[1]);
      }
    }
  }, [products]);

  const updateLeftProduct = uuid => {
    const selectedProd = products.find(p => p.uuid === uuid);
    if (selectedProd) {
      analytics.trackUnSelectProduct(leftProduct);
      setLeftProduct(selectedProd);
      analytics.trackSelectProduct(selectedProd);
    }
  };

  const updateRightProduct = uuid => {
    const selectedProd = products.find(p => p.uuid === uuid);
    if (selectedProd) {
      analytics.trackUnSelectProduct(rightProduct);
      setRightProduct(selectedProd);
      analytics.trackSelectProduct(selectedProd);
    }
  };

  if (isLoading) return <Loader bgColor={colors.grey5} color={colors.green2} />;
  if (isError) return <ErrorMessage />;

  return (
    <InView
      as="div"
      onChange={inView => {
        if (!scrolled && inView) {
          analytics.trackTableIsVisible(products);
          setScrolled(true);
        }
      }}
      threshold={0.1}
    >
      <Container>
        {products && leftProduct && rightProduct && (
          <>
            <ProductCarousel.MobileProductCarousel
              leftProduct={leftProduct}
              setLeftProduct={updateLeftProduct}
              rightProduct={rightProduct}
              setRightProduct={updateRightProduct}
              products={products}
              desktopChartBackgroundColor={desktopChartBackgroundColor}
              showShopSelectContainer={
                compData?.chartData?.comparison_chart?.showShopSelectContainer
              }
            />
            <IMGsContainer>
              {[leftProduct, rightProduct].map(product => (
                <ProductImage key={product.uuid}>
                  <Image src={product.image_url} alt={product.name} />
                </ProductImage>
              ))}
            </IMGsContainer>
          </>
        )}
        {products && leftProduct && rightProduct && (
          <FeatureTable.MobileFeatureTable
            leftProduct={leftProduct}
            rightProduct={rightProduct}
            sections={sections}
            pickerBackgroundColor={pickerBackgroundColor}
            shopLabel={shopLabel}
            exploreLabel={exploreLabel}
            showPrice={showPrice}
            showProductSubtitle={showProductSubtitle}
            differencesLabel={differencesLabel}
            buttonColor={buttonColor}
            showShopSelectContainer={
              compData?.chartData?.comparison_chart?.showShopSelectContainer
            }
            legalCopies={compData?.chartData?.comparison_chart?.legal_copies}
            shopButtonPrimaryColor={shopButtonPrimaryColor}
            shopButtonPrimaryBorderColor={shopButtonPrimaryBorderColor}
            shopButtonTextPrimaryColor={shopButtonTextPrimaryColor}
            shopSelectButtonPrimaryColor={shopSelectButtonPrimaryColor}
            shopSelectButtonPrimaryBorderColor={
              shopSelectButtonPrimaryBorderColor
            }
            shopSelectButtonTextPrimaryColor={shopSelectButtonTextPrimaryColor}
          />
        )}
        <LegalCopy
          dangerouslySetInnerHTML={{
            __html: compData?.chartData?.comparison_chart?.legal_copy,
          }}
        />
      </Container>
    </InView>
  );
};

MobileWrapper.propTypes = {
  compData: PropTypes.shape({
    chartData: PropTypes.shape({
      comparison_chart: PropTypes.shape({
        showShopSelectContainer: PropTypes.bool,
        products: PropTypes.arrayOf(types.PRODUCT_PROP_TYPES),
        sections: PropTypes.arrayOf(types.SECTION_PROP_TYPES),
        legal_copies: PropTypes.arrayOf(types.LEGALCOPY_PROP_TYPES),
        legal_copy: PropTypes.string,
      }),
    }),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }).isRequired,
  buttonColor: PropTypes.string,
  pickerBackgroundColor: PropTypes.string,
  shopLabel: PropTypes.string,
  exploreLabel: PropTypes.string,
  desktopChartBackgroundColor: PropTypes.string,
  showPrice: PropTypes.bool,
  showProductSubtitle: PropTypes.bool,
  differencesLabel: PropTypes.string,
  shopButtonPrimaryColor: PropTypes.string,
  shopButtonPrimaryBorderColor: PropTypes.string,
  shopButtonTextPrimaryColor: PropTypes.string,
  shopSelectButtonPrimaryColor: PropTypes.string,
  shopSelectButtonPrimaryBorderColor: PropTypes.string,
  shopSelectButtonTextPrimaryColor: PropTypes.string,
};
MobileWrapper.defaultProps = {
  pickerBackgroundColor: '',
  shopLabel: '',
  exploreLabel: '',
  differencesLabel: '',
  buttonColor: '',
  desktopChartBackgroundColor: '',
  showPrice: false,
  showProductSubtitle: false,
  shopButtonPrimaryColor: '#447355',
  shopButtonPrimaryBorderColor: '#447355',
  shopButtonTextPrimaryColor: '#FFFFFF',
  shopSelectButtonPrimaryColor: '#447355',
  shopSelectButtonPrimaryBorderColor: '#447355',
  shopSelectButtonTextPrimaryColor: '#FFFFFF',
};

const IMGsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${({ theme }) => theme.mobileRT.getWidthValue(25)}px;
  padding: ${({ theme }) => `0 ${theme.mobileRT.getWidthValue(25)}px`};
`;

const ProductImage = styled.div`
  margin-top: ${({ theme }) => theme.mobileRT.getWidthValue(25)}px;
  margin-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(17)}px;
  height: ${({ theme }) => theme.mobileRT.getWidthValue(169)}px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const LegalCopy = styled.div`
  color: ${colors.grey1};
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(8)}px;
  font-family: ${families.primaryFont};
  padding: 0 ${({ theme }) => theme.mobileRT.getWidthValue(27)}px;
  margin-top: ${({ theme }) => theme.mobileRT.getWidthValue(48)}px;
`;

const Container = styled.div`
  background-color: ${colors.white};
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(84)}px;
  font-family: ${families.primaryFont};
`;

export default MobileWrapper;
