export const fontVariables = {
  families: {
    primaryFont: 'HarmoniaSans, Arial',
  },
  weights: {
    normal: 400,
    semibold: 600,
    bold: 700,
    black: 900,
  },
};

export const colors = {
  white: '#fff',
  grey1: '#6a768c',
  grey2: '#506b81',
  grey3: '#3b3b3b',
  grey4: '#f4f4f4',
  grey5: '#f6f7fa',
  grey6: '#fafafa',
  grey7: '#dee2e6',
  green1: '#518a70',
  green2: '#447355',
  green3: '#edf3f0',
  black: '#000000',
};

export default {
  fontVariables,
  colors,
};
