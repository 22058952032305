import PropTypes from 'prop-types';

const PRODUCT_PROP_TYPES = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  product_kind: PropTypes.string.isRequired,
  image_url: PropTypes.string,
});

export default PRODUCT_PROP_TYPES;
