import PropTypes from 'prop-types';

const FEATURE_PROP_TYPES = PropTypes.shape({
  uuid: PropTypes.string,
  name: PropTypes.string,
  only_for_mob_exp_display: PropTypes.bool,
});

FEATURE_PROP_TYPES.defaultProps = {
  uuid: '',
  name: '',
  only_for_mob_exp_display: false,
};

export default FEATURE_PROP_TYPES;
