import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import CustomPropTypes from '../../../utils/CustomPropTypes';
import ProductSlide from './ProductSlide';
import { fontVariables } from '../../../styles/variables';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowIcon from '../../../assets/images/sliderArrow@2x.png';

const { weights, families } = fontVariables;

const DesktopProductCarousel = ({
  title,
  products,
  updateProduct,
  pickerBackgroundColor,
  buttonColor,
  buttonHoverColor,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrows: true,
  };

  return (
    <Container pickerBackgroundColor={pickerBackgroundColor}>
      <Title>{title}</Title>
      <Wrapper buttonColor={buttonColor} hoverColor={buttonHoverColor}>
        <StyledSlider {...settings}>
          {products.map(p => (
            <ProductSlide
              key={p.uuid}
              product={p}
              updateProduct={updateProduct}
              buttonColor={buttonColor}
              pickerBackgroundColor={pickerBackgroundColor}
              buttonHoverColor={buttonHoverColor}
            />
          ))}
        </StyledSlider>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ pickerBackgroundColor }) => pickerBackgroundColor};
`;

const Wrapper = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    & * {
      min-height: 0px;
      min-width: 0px;
      max-width: none;
    }
    padding: ${({ theme }) => theme.webRT.getWidthValue(93)}px 0
      ${({ theme }) => theme.webRT.getWidthValue(117)}px;
    .slick-prev,
    .slick-next {
      opacity: 1;
      height: ${({ theme }) => theme.webRT.getWidthValue(59)}px;
      width: ${({ theme }) => theme.webRT.getWidthValue(59)}px;
      border-radius: 50%;
      z-index: 99;
      transform: rotate(0) translateY(-100%);
      transition: opacity 0.15s linear;
      background: url(${ArrowIcon}) no-repeat center center;
      background-color: ${({ buttonColor }) => buttonColor};
      background-size: ${({ theme }) => theme.webRT.getWidthValue(16)}px
        ${({ theme }) => theme.webRT.getWidthValue(16)}px;

      &:before {
        content: none;
      }

      &:hover {
        background-color: ${({ hoverColor }) => hoverColor};
        &:active {
          transition: none;
          opacity: 0.75;
        }
      }

      &.slick-disabled {
        opacity: 0;
        &:hover {
          cursor: default;
        }
      }
    }
    .slick-prev {
      left: -${({ theme }) => theme.webRT.getWidthValue(105)}px;
      transform: rotate(180deg) translateY(100%);
    }
    .slick-next {
      right: -${({ theme }) => theme.webRT.getWidthValue(96)}px;
    }
  }
`;

const StyledSlider = styled(Slider)`
  width: ${({ theme }) => theme.webRT.getWidthValue(1484)}px;
`;

const Title = styled.h2`
  font-family: ${families.primaryFont};
  padding-top: ${({ theme }) => theme.webRT.getWidthValue(117)}px;
  margin: 0 auto;
  text-align: center;
  max-width: ${({ theme }) => theme.webRT.getWidthValue(950)}px;
  font-size: ${({ theme }) => theme.webRT.getFontSize(64)}px;
  font-weight: ${weights.bold};
  line-height: ${({ theme }) => theme.webRT.getFontSize(64)}px;
`;

DesktopProductCarousel.propTypes = {
  title: PropTypes.string,
  products: PropTypes.arrayOf(CustomPropTypes.PRODUCT_PROP_TYPES),
  updateProduct: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  pickerBackgroundColor: PropTypes.string.isRequired,
  buttonHoverColor: PropTypes.string.isRequired,
};

DesktopProductCarousel.defaultProps = {
  title: '',
  products: [],
};

export default DesktopProductCarousel;
