import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import types from '../../../../utils/CustomPropTypes';
import { fontVariables } from '../../../../styles/variables';
import Subsection from '../Subsection';
import { shouldDisplaySection } from '../../../../utils/DifferencesUtils/DifferencesUtils';

const { weights, families } = fontVariables;

// this logic hides the section if the flag showShopSelectContainer is false and the
// section has isShopSelect set to true
export const checkShowShopSelect = (
  isShopSelect = false,
  showShopSelectContainer = false
) => !(!showShopSelectContainer && isShopSelect);

const Section = ({
  leftProduct,
  rightProduct,
  section,
  differencesOnly,
  showShopSelectContainer,
  footerKeys,
}) =>
  shouldDisplaySection(section, [leftProduct, rightProduct], differencesOnly) &&
  checkShowShopSelect(section?.isShopSelect, showShopSelectContainer) && (
    <>
      <Row top={48}>
        <SectionTitle font={section.font} color={section.font_color}>
          {section.name}
        </SectionTitle>
      </Row>
      {section.subsections.map(subsection => (
        <Subsection
          leftProduct={leftProduct}
          rightProduct={rightProduct}
          subsection={subsection}
          key={subsection.uuid}
          differencesOnly={differencesOnly}
          footerKeys={footerKeys}
        />
      ))}
    </>
  );

const Row = styled.div`
  text-align: center;
  padding-left: ${({ theme }) => theme.mobileRT.getWidthValue(28)}px;
  padding-right: ${({ theme }) => theme.mobileRT.getWidthValue(28)}px;
  padding-top: ${({ theme, top }) => theme.mobileRT.getWidthValue(top)}px;
`;

const SectionTitle = styled.h2`
  font-family: ${({ font }) => font || families.primaryFont};
  color: ${({ color }) => color};
  margin: 0;
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(32)}px;
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(32)}px;
  font-weight: ${weights.normal};
`;

Section.propTypes = {
  leftProduct: types.PRODUCT_PROP_TYPES.isRequired,
  rightProduct: types.PRODUCT_PROP_TYPES.isRequired,
  section: types.SECTION_PROP_TYPES.isRequired,
  differencesOnly: PropTypes.bool.isRequired,
  showShopSelectContainer: PropTypes.bool,
  footerKeys: PropTypes.arrayOf(PropTypes.string),
};

Section.defaultProps = {
  showShopSelectContainer: false,
  footerKeys: [],
};

export default Section;
