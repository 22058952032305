import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import ProductCarousel from '../../ProductCarousel';
import types from '../../../utils/CustomPropTypes';
import FeatureTable from '../../FeatureTable';
import { colors, fontVariables } from '../../../styles/variables';
import analytics from '../../../utils/analytics';
import Loader from '../../Loader';
import ErrorMessage from '../../ErrorMessage';

const { families } = fontVariables;

const DesktopWrapper = ({
  title,
  compData,
  buttonColor,
  pickerBackgroundColor,
  buttonHoverColor,
  desktopColumnColor,
  desktopChartBackgroundColor,
  shopLabel,
  exploreLabel,
  showPrice,
  showProductSubtitle,
  differencesLabel,
  yesLabel,
  shopButtonPrimaryColor,
  shopButtonPrimaryBorderColor,
  shopButtonTextPrimaryColor,
  shopButtonTextHoverColor,
  shopButtonHoverColor,
  shopButtonHoverBorderColor,
  shopSelectButtonPrimaryColor,
  shopSelectButtonPrimaryBorderColor,
  shopSelectButtonTextPrimaryColor,
  shopSelectButtonTextHoverColor,
  shopSelectButtonHoverColor,
  shopSelectButtonHoverBorderColor,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [sections, setSections] = useState([]);
  const [scrolled, setScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    analytics.trackLoad();
  }, []);

  useEffect(() => {
    if (!isEmpty(compData) && !isEmpty(compData.chartData)) {
      setProducts(compData?.chartData?.comparison_chart?.products);
      setSections(compData?.chartData?.comparison_chart?.sections);
    }
    if (isError !== compData?.isError) {
      setIsError(compData?.isError);
    }
    if (isLoading !== compData?.isLoading) {
      setIsLoading(compData.isLoading);
    }
    return () => {};
  }, [compData, isError, isLoading]);

  useEffect(() => {
    if (products && products.length > 0) {
      setSelectedProducts(
        products.map(p => ({ ...p, selected: p.desktop_preselected }))
      );
    }
  }, [products]);

  const updateProduct = uuid => {
    const updatedList = selectedProducts.map(p => {
      if (p.uuid === uuid) {
        if (p.selected) analytics.trackUnSelectProduct(p);
        else analytics.trackSelectProduct(p);
        return { ...p, selected: !p.selected };
      }
      return p;
    });
    setSelectedProducts(updatedList);
  };

  if (isLoading) return <Loader bgColor={colors.grey5} color={colors.green2} />;

  if (isError) return <ErrorMessage />;

  return (
    <InView
      as="div"
      onChange={inView => {
        if (!scrolled && inView) {
          analytics.trackTableIsVisible(products);
          setScrolled(true);
        }
      }}
      threshold={0.1}
    >
      <Container>
        {selectedProducts.length > 0 && (
          <ProductCarousel.DesktopProductCarousel
            title={title}
            updateProduct={updateProduct}
            products={selectedProducts}
            buttonColor={buttonColor}
            pickerBackgroundColor={pickerBackgroundColor}
            buttonHoverColor={buttonHoverColor}
          />
        )}
        <div id="comp-chart-anchor" />
        {selectedProducts.length > 0 && (
          <FeatureTable.DesktopFeatureTable
            products={selectedProducts.filter(p => p.selected)}
            desktopColumnColor={desktopColumnColor}
            desktopChartBackgroundColor={desktopChartBackgroundColor}
            shopLabel={shopLabel}
            exploreLabel={exploreLabel}
            differencesLabel={differencesLabel}
            yesLabel={yesLabel}
            sections={sections}
            showPrice={showPrice}
            showProductSubtitle={showProductSubtitle}
            legalCopies={compData?.chartData?.comparison_chart?.legal_copies}
            showShopSelectContainer={
              compData?.chartData?.comparison_chart?.showShopSelectContainer
            }
            buttonColor={buttonColor}
            buttonHoverColor={buttonHoverColor}
            shopButtonPrimaryColor={shopButtonPrimaryColor}
            shopButtonPrimaryBorderColor={shopButtonPrimaryBorderColor}
            shopButtonTextPrimaryColor={shopButtonTextPrimaryColor}
            shopButtonTextHoverColor={shopButtonTextHoverColor}
            shopButtonHoverColor={shopButtonHoverColor}
            shopButtonHoverBorderColor={shopButtonHoverBorderColor}
            shopSelectButtonPrimaryColor={shopSelectButtonPrimaryColor}
            shopSelectButtonPrimaryBorderColor={
              shopSelectButtonPrimaryBorderColor
            }
            shopSelectButtonTextPrimaryColor={shopSelectButtonTextPrimaryColor}
            shopSelectButtonTextHoverColor={shopSelectButtonTextHoverColor}
            shopSelectButtonHoverColor={shopSelectButtonHoverColor}
            shopSelectButtonHoverBorderColor={shopSelectButtonHoverBorderColor}
          />
        )}
        <LegalCopy
          dangerouslySetInnerHTML={{
            __html: compData?.chartData?.comparison_chart?.legal_copy,
          }}
        />
      </Container>
    </InView>
  );
};

DesktopWrapper.propTypes = {
  title: PropTypes.string,
  compData: PropTypes.shape({
    chartData: PropTypes.shape({
      comparison_chart: PropTypes.shape({
        showShopSelectContainer: PropTypes.bool,
        products: PropTypes.arrayOf(types.PRODUCT_PROP_TYPES),
        sections: PropTypes.arrayOf(types.SECTION_PROP_TYPES),
        legal_copies: PropTypes.arrayOf(types.LEGALCOPY_PROP_TYPES),
        legal_copy: PropTypes.string,
      }),
    }),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }).isRequired,
  buttonColor: PropTypes.string,
  pickerBackgroundColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  desktopColumnColor: PropTypes.string,
  desktopChartBackgroundColor: PropTypes.string,
  shopLabel: PropTypes.string,
  exploreLabel: PropTypes.string,
  differencesLabel: PropTypes.string,
  yesLabel: PropTypes.string,
  showPrice: PropTypes.bool,
  showProductSubtitle: PropTypes.bool,
  shopButtonPrimaryColor: PropTypes.string,
  shopButtonPrimaryBorderColor: PropTypes.string,
  shopButtonTextPrimaryColor: PropTypes.string,
  shopButtonTextHoverColor: PropTypes.string,
  shopButtonHoverColor: PropTypes.string,
  shopButtonHoverBorderColor: PropTypes.string,
  shopSelectButtonPrimaryColor: PropTypes.string,
  shopSelectButtonPrimaryBorderColor: PropTypes.string,
  shopSelectButtonTextPrimaryColor: PropTypes.string,
  shopSelectButtonTextHoverColor: PropTypes.string,
  shopSelectButtonHoverColor: PropTypes.string,
  shopSelectButtonHoverBorderColor: PropTypes.string,
};

DesktopWrapper.defaultProps = {
  title: '',
  pickerBackgroundColor: '',
  desktopColumnColor: '',
  desktopChartBackgroundColor: '',
  shopLabel: '',
  exploreLabel: '',
  yesLabel: '',
  differencesLabel: '',
  showPrice: false,
  showProductSubtitle: false,
  buttonColor: '',
  buttonHoverColor: '',
  shopButtonPrimaryColor: '#447355',
  shopButtonPrimaryBorderColor: '#447355',
  shopButtonTextPrimaryColor: '#FFFFFF',
  shopButtonTextHoverColor: '#FFFFFF',
  shopButtonHoverColor: '#518A70',
  shopButtonHoverBorderColor: '#518A70',
  shopSelectButtonPrimaryColor: '#447355',
  shopSelectButtonPrimaryBorderColor: '#447355',
  shopSelectButtonTextPrimaryColor: '#FFFFFF',
  shopSelectButtonTextHoverColor: '#FFFFFF',
  shopSelectButtonHoverColor: '#518A70',
  shopSelectButtonHoverBorderColor: '#518A70',
};

const Container = styled.div`
  padding-bottom: ${({ theme }) => theme.webRT.getWidthValue(100)}px;
  background-color: ${colors.white};
  font-family: ${families.primaryFont};
`;

const LegalCopy = styled.div`
  color: ${colors.grey1};
  font-size: ${({ theme }) => theme.webRT.getFontSize(12)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(27)}px;
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(144)}px;
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(100)}px;
  font-family: ${families.primaryFont};
`;

export default DesktopWrapper;
