import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import {
  fetchCompMock,
  fetchCompChartStaticData,
  fetchCompChartDynamicData,
} from '../../services/client';

const PDP_PRODUCT_REGEX = /\/([a-zA-Z]*[0-9].*?)\.html/;

function getSKUsfromURL() {
  const parsedString = queryString.parse(window.location.search, {
    // needed because, when using the short_names as identifiers, the + character
    // gets decoded as a white space.
    // why? https://github.com/sindresorhus/query-string/issues/305
    decode: false,
  });

  const { recommendedProducts } = parsedString;
  return recommendedProducts ? recommendedProducts.split(',') : null;
}

function getSKUfromPDP() {
  let isPDP = false;
  let PDPsku = '';

  try {
    const [, sku] = window.location.pathname.match(PDP_PRODUCT_REGEX);

    isPDP = true;
    PDPsku = sku.toLowerCase();
  } catch (_) {
    // not a PDP page
  }

  return {
    isPDP,
    PDPsku,
  };
}

function getDataWithPreselectedProducts(data = {}, recommendedSKUs = []) {
  if (!data?.comparison_chart?.products) return data;

  const preselectedProducts = data.comparison_chart.products.map(product => ({
    ...product,
    desktop_preselected: recommendedSKUs.some(
      sku => sku === product.sku || sku === product.short_name
    ),
    mobile_preselected: recommendedSKUs.some(
      sku => sku === product.sku || sku === product.short_name
    ),
  }));

  const updatedData = {
    ...data,
    comparison_chart: {
      ...data.comparison_chart,
      products: preselectedProducts,
    },
  };

  return updatedData;
}

function getDataWithPDPProduct(data = {}, PDPsku = '') {
  if (!data?.comparison_chart?.products) return data;

  let PDPproduct;

  let filteredProducts = data.comparison_chart.products.filter(prod => {
    if (prod.sku === PDPsku || prod.short_name === PDPsku) {
      PDPproduct = {
        ...prod,
        desktop_preselected: true,
        mobile_preselected: true,
      };
      // remove it from the current array
      return false;
    }
    // pass
    return true;
  });

  // if PDPproduct found, put it in the first position
  if (PDPproduct) {
    filteredProducts = [PDPproduct, ...filteredProducts];
  }

  const updatedData = {
    ...data,
    comparison_chart: {
      ...data.comparison_chart,
      products: filteredProducts,
    },
  };

  return updatedData;
}

/* eslint-disable camelcase */
function getParsedData({
  comparison_chart = {},
  labels = {},
  pixel_info = {},
}) {
  const parsedProducts = comparison_chart?.products?.map(prod => {
    // let productName = `${prod?.product_kind?.product_name} ${prod?.name}`;
    let productName = prod?.name;
    const product_kind = prod?.product_kind?.name
      .toLowerCase()
      .replace(/_/i, ' ');

    /* if (prod?.combo && prod?.product_kind?.product_name === 'Bundle') {
      productName = `Roomba Combo® ${prod?.name}`;
    } */

    if (productName.includes('®')) {
      productName = productName.replace(/®/i, '<sup>®</sup>');
    }

    const product_features = prod?.product_features.map(prodFeat => ({
      ...prodFeat,
      feature: prodFeat?.feature?.uuid,
      selected_option: prodFeat?.selected_option?.uuid || null,
    }));

    return {
      ...prod,
      name: productName,
      product_kind,
      product_features,
      short_name: prod.short_name || prod.tag || prod.name,
      explore_copy: prod.shop_select_description,
      explore_link: prod.shop_select_page_url,
      display_explore: prod.show_shop_select_button,
    };
  });

  const legal_copies = [];

  const parsedSections = comparison_chart?.sections.map(section => ({
    ...section,
    subsections: section.subsections.map(subSection => ({
      ...subSection,
      features: subSection.features.map(features => {
        // here we fill up the legal copies array
        if (features.feature.legal_copy) {
          legal_copies.push({
            uuid: features.feature.legal_copy,
            copy: features.feature.legal_copy,
          });
        }

        return {
          ...features,
          ...features.feature,
          feature_options: features.feature.feature_options.map(feat_opt => {
            if (feat_opt.legal_copy || feat_opt.legal_copy_uuid) {
              legal_copies.push({
                // TODO: when Anab rename this prop in the BE, update this part
                uuid: feat_opt.legal_copy || feat_opt.legal_copy_uuid,
                copy: feat_opt.legal_copy || feat_opt.legal_copy_uuid,
              });
            }

            return {
              ...feat_opt,
              legal_copy_uuid: feat_opt.legal_copy_uuid || feat_opt.legal_copy,
            };
          }),
        };
      }),
    })),
  }));

  return {
    comparison_chart: {
      ...comparison_chart,
      products: [...parsedProducts],
      sections: [...parsedSections],
      legal_copies,
    },
    labels,
    pixel_info,
  };
}
/* eslint-disable camelcase */

export const connect = WrappedComponent => props => {
  // Used to generate JSON file of mock data
  // console.log(JSON.stringify(compMockData));
  const [currentCompData, setCurrentCompData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const setData = data => {
    const newData = data;
    // Display select container only if at least one product has display_explore enabled
    newData.comparison_chart.showShopSelectContainer =
      data.comparison_chart.products.some(p => p.display_explore);
    setCurrentCompData(newData);
  };

  useEffect(() => {
    // Load dynamic cms data
    // eslint-disable-next-line no-unused-vars
    async function getCompChartDynamicData() {
      try {
        const { apiUrl, experienceId } = document.getElementById(
          'irobot-embedded-comp-chart'
        ).dataset;
        setIsLoading(true);
        const { data: fetchedData } = await fetchCompChartDynamicData(
          apiUrl,
          experienceId
        );

        const parsedData = getParsedData(fetchedData);
        const recommendedProducts = getSKUsfromURL();
        const { isPDP, PDPsku } = getSKUfromPDP();

        if (parsedData) {
          setIsLoading(false);
          if (isPDP) {
            setData({
              ...getDataWithPDPProduct(parsedData, PDPsku),
            });
          } else if (recommendedProducts) {
            setData({
              ...getDataWithPreselectedProducts(
                parsedData,
                recommendedProducts
              ),
            });
          } else {
            setData(parsedData);
          }
        } else {
          setIsLoading(false);
          setIsError(true);
          console.error('Error parsing chart data');
        }
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        console.error('Error loading comp chart data:', error);
      }
    }
    // Load static cms data from server
    // eslint-disable-next-line no-unused-vars
    async function getCompChartStaticData() {
      try {
        const { apiUrl, experienceId } = document.getElementById(
          'irobot-embedded-comp-chart'
        ).dataset;
        const { data: fetchedData } = await fetchCompChartStaticData(
          apiUrl,
          experienceId
        );

        const recommendedProducts = getSKUsfromURL();
        const { isPDP, PDPsku } = getSKUfromPDP();

        if (fetchedData) {
          if (isPDP) {
            setData({
              ...getDataWithPDPProduct(fetchedData, PDPsku),
            });
          } else if (recommendedProducts) {
            setData({
              ...getDataWithPreselectedProducts(
                fetchedData,
                recommendedProducts
              ),
            });
          } else {
            setData(fetchedData);
          }
        }
      } catch (error) {
        console.error('Error loading comp chart data:', error);
      }
    }
    // Load mocked response
    // eslint-disable-next-line no-unused-vars
    async function getCompMockData() {
      try {
        const response = await fetchCompMock(
          '',
          'db71f45c-568c-4129-b2f9-da47f00fe547'
        );
        let data = response?.data;

        const recommendedSKUs = getSKUsfromURL();

        if (recommendedSKUs) {
          data = getDataWithPreselectedProducts(
            response?.data,
            recommendedSKUs
          );
        }

        setData(data || response?.data);
      } catch (error) {
        console.error('Error loading comp chart data:', error);
      }
    }

    getCompChartDynamicData();
    // getCompChartStaticData();
    // getCompMockData();
    return () => {};
  }, []);
  return (
    <WrappedComponent
      compData={currentCompData}
      isLoading={isLoading}
      isError={isError}
      {...props}
    />
  );
};

export default connect;
