import uniqueId from 'uniqid';

let session = null;
/* eslint-disable camelcase */
const parseProduct = ({
  uuid,
  product_kind,
  name,
  sku,
  link,
  desktop_preselected,
  short_name,
  mobile_preselected,
}) => {
  try {
    return {
      uuid,
      product_kind,
      name,
      short_name,
      sku,
      link,
      desktop_preselected,
      mobile_preselected,
    };
  } catch (e) {
    return {};
  }
};
/* eslint-enable camelcase */

export const onSelectProduct = (pixel = () => {}, product = {}) => {
  try {
    pixel('event', 'productSelect', {
      session,
      product: parseProduct(product),
    });
  } catch (e) {
    console.error(e);
  }
};

export const onUnselectProduct = (pixel = () => {}, product = {}) => {
  try {
    pixel('event', 'productUnselect', {
      session,
      product: parseProduct(product),
    });
  } catch (e) {
    console.error(e);
  }
};

export const onClickShop = (pixel = () => {}, product = {}) => {
  try {
    pixel('event', 'productShopClick', {
      session,
      product: parseProduct(product),
    });
  } catch (e) {
    console.error(e);
  }
};

export const onLoad = () => {
  session = uniqueId();
};

export default {
  onSelectProduct,
  onUnselectProduct,
  onClickShop,
  onLoad,
};
