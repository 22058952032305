import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactToggle from 'react-toggle';
import { colors, fontVariables } from '../../styles/variables';

const { weights, families } = fontVariables;

const Toggle = ({
  defaultChecked,
  handleToggle,
  label,
  color,
  backgroundColor,
}) => (
  <StyledLabel color={color} backgroundColor={backgroundColor}>
    <ReactToggle
      defaultChecked={defaultChecked}
      onChange={handleToggle}
      icons={false}
    />
    <span>{label}</span>
  </StyledLabel>
);

const StyledLabel = styled.label`
  &&& {
    font-size: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(14)
        : theme.webRT.getFontSize(20)}px;
    line-height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(28)
        : theme.webRT.getFontSize(28)}px;
    font-weight: ${weights.bold};
    color: ${({ theme, color }) => (theme.isMobile ? color : colors.black)};
    display: flex;
    align-items: center;
    border-left: 0;
    font-family: ${families.primaryFont};

    .react-toggle-track {
      height: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(25)
          : theme.webRT.getWidthValue(25)}px;
      width: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(43)
          : theme.webRT.getWidthValue(42)}px;
      padding: 0;
      border-radius: 30px;
      background-color: ${({ backgroundColor }) => backgroundColor};
      transition: all 0.2s ease;
    }
    .react-toggle-thumb {
      height: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(21)
          : theme.webRT.getWidthValue(21)}px;
      width: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(21)
          : theme.webRT.getWidthValue(21)}px;
      top: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(2)
          : theme.webRT.getWidthValue(2)}px;
      left: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(2)
          : theme.webRT.getWidthValue(2)}px;
      box-shadow: none;
    }

    .react-toggle--checked {
      .react-toggle-thumb {
        left: ${({ theme }) =>
          theme.isMobile
            ? theme.mobileRT.getWidthValue(20)
            : theme.webRT.getWidthValue(19)}px;
        border-color: ${({ backgroundColor }) => backgroundColor};
      }
      .react-toggle-track {
        background-color: ${({ color }) => color};
      }
    }
    .react-toggle {
      touch-action: pan-x;
      display: inline-block;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      padding: 0;
      user-select: none;
      vertical-align: ${({ theme }) => (theme.isMobile ? 'bottom' : 'inherit')};
      margin-bottom: 5px;
    }
    .react-toggle-thumb {
      transition: left 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      position: absolute;
      border-radius: 50%;
      background-color: ${colors.grey6};
      box-sizing: border-box;
    }
    .react-toggle-screenreader-only {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
`;

Toggle.propTypes = {
  defaultChecked: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  label: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Toggle.defaultProps = {
  label: '',
  color: colors.green2,
  backgroundColor: colors.grey1,
};

export default Toggle;
