import {
  onTableIsVisible,
  onShowOnlyDifferences,
  onSelectProduct,
  onUnselectProduct,
  onClickShop,
} from './ga';
import {
  onSelectProduct as pxSelectProduct,
  onUnselectProduct as pxUnselectProduct,
  onClickShop as pxClickShop,
  onLoad as pxLoad,
} from './pixel';

const { dataLayer, genomeTP } = window;

export const trackTableIsVisible = (products = []) => {
  onTableIsVisible(dataLayer, products);
};

export const trackShowOnlyDifferences = (toggleStatus = false) => {
  onShowOnlyDifferences(dataLayer, toggleStatus);
};

export const trackSelectProduct = (product = {}) => {
  onSelectProduct(dataLayer, product);
  pxSelectProduct(genomeTP, product);
};

export const trackUnSelectProduct = (product = {}) => {
  onUnselectProduct(dataLayer, product);
  pxUnselectProduct(genomeTP, product);
};

export const trackClickShop = (product = {}, isExploreLink = false) => {
  onClickShop(dataLayer, product, isExploreLink);
  pxClickShop(genomeTP, product);
};

export const trackLoad = () => {
  pxLoad();
};

export default {
  trackTableIsVisible,
  trackShowOnlyDifferences,
  trackClickShop,
  trackLoad,
  trackSelectProduct,
  trackUnSelectProduct,
};
