import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import types from '../../../../utils/CustomPropTypes';
import { fontVariables, colors } from '../../../../styles/variables';
import { shouldDisplayFeature } from '../../../../utils/DifferencesUtils/DifferencesUtils';
import Image from '../../../Image';

const { weights, families } = fontVariables;

const Feature = ({
  feature,
  products,
  className,
  differencesOnly,
  yesLabel,
  border,
  footerKeys,
}) => {
  const featureClassName =
    className ||
    (shouldDisplayFeature(feature, products, differencesOnly)
      ? ''
      : 'collapsed');
  return (
    <CollapsibleRow
      key={feature.uuid}
      className={featureClassName}
      border={border}
    >
      <th>
        <FeatureWrapper border={border}>
          <FeatureName>
            <p className="feature-name">
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: feature.name }} />
              {feature.legal_copy && (
                <sup>{footerKeys.indexOf(feature.legal_copy) + 1}</sup>
              )}
              {feature.show_tooltip && (
                <TooltipWrapper>
                  <TooltipIcon data-tip={feature.tooltip_description}>
                    <span>?</span>
                  </TooltipIcon>
                </TooltipWrapper>
              )}
            </p>
          </FeatureName>
        </FeatureWrapper>
      </th>
      {products.map(p => {
        let content = <div />;
        const f = p.product_features.find(pf => pf.feature === feature.uuid);
        // TODO: this if else block could be more clear
        if (f) {
          if (f.selected_option) {
            const option = feature.feature_options.find(
              fo => fo.uuid === f.selected_option
            );
            if (option) {
              const {
                image_url: optionIcon,
                name: optionText,
                alt_tag: alt,
              } = option;
              if (optionIcon) {
                content = (
                  <OptionIcon>
                    <Image
                      src={optionIcon}
                      srcSet={`${optionIcon} 2x`}
                      alt={alt}
                    />
                    {option.legal_copy_uuid && (
                      <OptionIconNumber>
                        {footerKeys.indexOf(option.legal_copy_uuid) + 1}
                      </OptionIconNumber>
                    )}
                  </OptionIcon>
                );
              } else {
                content = optionText.includes('*') ? (
                  <OptionText>
                    <span>
                      {optionText.split('*')[0]}
                      {option.legal_copy_uuid && (
                        <OptionDisclaimerNumber>
                          {footerKeys.indexOf(option.legal_copy_uuid) + 1}
                        </OptionDisclaimerNumber>
                      )}
                      {optionText.split('*')[1]}
                    </span>
                  </OptionText>
                ) : (
                  <OptionText>
                    <span>
                      {optionText}
                      {option.legal_copy_uuid && (
                        <OptionDisclaimerNumber>
                          {footerKeys.indexOf(option.legal_copy_uuid) + 1}
                        </OptionDisclaimerNumber>
                      )}
                    </span>
                  </OptionText>
                );
              }
            } else {
              content = (
                <OptionIcon>
                  <img
                    src="https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/DesktopCheck.svg"
                    alt={yesLabel}
                  />
                </OptionIcon>
              );
            }
          } else {
            content = (
              <OptionIcon>
                <img
                  src="https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/DesktopCheck.svg"
                  alt={yesLabel}
                />
              </OptionIcon>
            );
          }
        }
        return <td key={p.uuid}>{content}</td>;
      })}
    </CollapsibleRow>
  );
};

const FeatureName = styled.div`
  max-width: ${({ theme }) => theme.webRT.getWidthValue(493)}px;
  margin-top: ${({ theme }) => theme.webRT.getWidthValue(22)}px;
  margin-bottom: ${({ theme }) => theme.webRT.getWidthValue(23)}px;
  p.feature-name {
    margin: 0;
    color: ${colors.grey1};
    font-weight: ${weights.semibold};
    font-size: ${({ theme }) => theme.webRT.getFontSize(24)}px;
    font-family: ${families.primaryFont};
    line-height: ${({ theme }) => theme.webRT.getFontSize(30)}px;
    float: left;
  }
`;

const CollapsibleRow = styled.tr`
  &.collapsed {
    div {
      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  td {
    box-shadow: ${({ border }) => (border ? `0 -2px ${colors.grey7}` : 'none')};
    :last-of-type {
      box-shadow: ${({ border, theme }) =>
        border
          ? `${theme.webRT.getWidthValue(-13)}px 0px ${colors.white},
        ${theme.webRT.getWidthValue(-13)}px -2px ${colors.grey7}`
          : 'none'};
    }
    div {
      box-shadow: ${({ border }) =>
        border ? `0 -2px ${colors.grey7}` : 'none'};
    }
  }
  td,
  th {
    div {
      max-height: 500px;
      overflow: hidden;
      transition: max-height 0.3s, margin 0.5s;
    }
  }
`;

const FeatureWrapper = styled.div`
  box-shadow: ${({ border }) => (border ? `0 -2px ${colors.grey7}` : 'none')};
  padding-left: ${({ theme }) => theme.webRT.getWidthValue(28)}px;
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(156)}px;
`;
const OptionText = styled.div`
  font-family: ${families.primaryFont};
  font-size: ${({ theme }) => theme.webRT.getFontSize(20)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(20)}px;
  color: ${colors.green1};
  font-weight: ${weights.bold};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TooltipWrapper = styled.div`
  ${CollapsibleRow} th & {
    display: inline-block;
    overflow: visible;
    margin-left: ${({ theme }) => theme.webRT.getWidthValue(7)}px;
    max-height: none;
    transition: none;
  }
`;

const TooltipIcon = styled.button`
  height: ${({ theme }) => theme.webRT.getWidthValue(31)}px;
  width: ${({ theme }) => theme.webRT.getWidthValue(31)}px;
  border: none;
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(3)}px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: 1px solid ${colors.grey1};
  background-color: rgba(106, 118, 140, 0.08);
  border-radius: 50%;
  span {
    font-weight: ${weights.bold};
    color: ${colors.grey1};
    font-family: ${families.primaryFont};
    font-size: ${({ theme }) => theme.webRT.getFontSize(20)}px;
    line-height: 1.6;
  }
  &:hover {
    background-color: ${colors.grey1};
    span {
      color: ${colors.white};
    }
  }
`;

const OptionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: ${({ theme }) => theme.webRT.getWidthValue(45)}px;
  }
`;

const OptionIconNumber = styled.span`
  font-family: ${families.primaryFont};
  font-size: ${({ theme }) => theme.webRT.getFontSize(18)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(20)}px;
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(1)}px;
  color: ${colors.green1};
  font-weight: ${weights.bold};
`;

const OptionDisclaimerNumber = styled.sup`
  font-family: ${families.primaryFont};
  font-size: ${({ theme }) => theme.webRT.getFontSize(18)}px;
  line-height: ${({ theme }) => theme.webRT.getFontSize(20)}px;
  margin-left: ${({ theme }) => theme.webRT.getWidthValue(1)}px;
  color: ${colors.green1};
  font-weight: ${weights.bold};
`;

Feature.propTypes = {
  products: PropTypes.arrayOf(types.PRODUCT_PROP_TYPES).isRequired,
  feature: types.FEATURE_PROP_TYPES.isRequired,
  className: PropTypes.string.isRequired,
  differencesOnly: PropTypes.bool.isRequired,
  border: PropTypes.bool.isRequired,
  yesLabel: PropTypes.string.isRequired,
  footerKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Feature.defaultProps = {};

export default Feature;
