import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import types from '../../../../utils/CustomPropTypes';
import { fontVariables, colors } from '../../../../styles/variables';
import Separator from '../../../Separator';
import CONST from '../../../../data/constants';
import QuestionIcon from '../../../../assets/images/featureTooltip@2x.png';
import Image from '../../../Image';

const { weights, families } = fontVariables;
const { H_LENGTHS, V_LENGTHS } = CONST;
const NoIcon =
  'https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/Cancel.svg';
const CheckIcon =
  'https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/Check.svg';
const CloseIcon =
  'https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/Close.svg';

const Feature = ({
  feature,
  border,
  left,
  right,
  differencesOnly,
  footerKeys,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const { only_for_mob_exp_display: onlyMobile } = feature;

  if (
    differencesOnly &&
    left?.feature === right?.feature &&
    left?.selected_option === right?.selected_option
  ) {
    return null;
  }
  const Tooltip = (
    <TooltipContainer>
      <TooltipClose onClick={() => setTooltipOpen(false)}>
        <img width="100%" src={CloseIcon} alt="Close" />
      </TooltipClose>
      <TooltipCopy
        dangerouslySetInnerHTML={{ __html: feature.tooltip_description }}
      />
    </TooltipContainer>
  );

  const Together = (
    <Wrapper>
      {onlyMobile ? <div /> : <Icon src={left ? CheckIcon : NoIcon} />}
      <CenterWrap>
        {feature.icon_url && (
          <FeatureIcon
            src={feature.icon_url}
            srcSet={`${feature.icon_url} 2x`}
            alt={feature.name}
          />
        )}

        {!onlyMobile && (
          // eslint-disable-next-line react/no-danger
          <span>
            {feature.name}
            {feature.legal_copy && (
              <sup>{footerKeys.indexOf(feature.legal_copy) + 1}</sup>
            )}
          </span>
        )}

        {feature.show_tooltip && (
          <TooltipIcon onClick={() => setTooltipOpen(!tooltipOpen)} />
        )}
        {tooltipOpen && Tooltip}
      </CenterWrap>
      {onlyMobile ? <div /> : <Icon src={right ? CheckIcon : NoIcon} />}
    </Wrapper>
  );

  const GetTopContent = (selected, parent) => {
    const option = parent.feature_options.find(
      f => f.uuid === selected.selected_option
    );
    let top = null;
    const { icon_url: featureIcon } = parent;

    if (option) {
      const { image_url: optionIcon, name: optionText, alt_tag: alt } = option;
      const optionNumber = option.legal_copy_uuid && (
        <OptionDisclaimerNumber>
          {footerKeys.indexOf(option.legal_copy_uuid) + 1}
        </OptionDisclaimerNumber>
      );
      if (optionIcon)
        top = (
          <>
            <Image src={optionIcon} srcSet={`${optionIcon} 2x`} alt={alt} />
            {optionNumber}
          </>
        );
      else if (optionText)
        top =
          optionText && optionText.includes('*') ? (
            <OptionText>
              {optionText.split('*')[0]}
              {option.legal_copy_uuid && (
                <OptionDisclaimerNumber>
                  {footerKeys.indexOf(option.legal_copy_uuid) + 1}
                </OptionDisclaimerNumber>
              )}
              {optionText.split('*')[1]}
            </OptionText>
          ) : (
            <OptionText>
              {optionText}
              {optionNumber}
            </OptionText>
          );
    }
    if (top === null) {
      if (featureIcon) {
        const optionNumber = feature.legal_copy && (
          <OptionIconNumber>
            {footerKeys.indexOf(feature.legal_copy) + 1}
          </OptionIconNumber>
        );
        top = (
          <>
            <img
              src={featureIcon}
              srcSet={`${featureIcon} 2x`}
              alt={feature.name}
            />
            {optionNumber}
          </>
        );
      }
    }
    return top;
  };

  const GetBottomContent = parent => {
    const { name } = parent;
    const bottom = onlyMobile ? null : (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={{ __html: name }} />
    );
    return bottom;
  };

  const GetContent = (selected, parent) => {
    if (selected === undefined || selected === null) {
      return <Icon className="separate" src={NoIcon} alt="No" />;
    }
    return (
      <>
        <div>{GetTopContent(selected, parent)}</div>
        <div>{GetBottomContent(parent)}</div>
      </>
    );
  };

  const Separate = (
    <>
      <Wrapper>
        <Cell>{GetContent(left, feature)}</Cell>
        <Separator.Vertical length={V_LENGTHS.HALF} />
        <Cell>{GetContent(right, feature)}</Cell>
      </Wrapper>
    </>
  );

  if (
    (left &&
      right &&
      left.selected_option === null &&
      right.selected_option === null) ||
    (left === undefined && right === undefined)
  ) {
    return (
      <>
        {Together}
        {border && <Separator.Horizontal length={H_LENGTHS.PARTIAL} />}
      </>
    );
  }
  if (
    left === undefined ||
    left === null ||
    right === undefined ||
    right === null
  ) {
    return <>{Separate}</>;
  }
  return (
    <>
      <Wrapper>
        <TopCell>{GetTopContent(left, feature)}</TopCell>
        <Separator.Vertical length={V_LENGTHS.TOP} />
        <TopCell>{GetTopContent(right, feature)}</TopCell>
      </Wrapper>
      <Wrapper>
        <BottomCell>{GetBottomContent(feature)}</BottomCell>
        <Separator.Vertical length={V_LENGTHS.BOTTOM} />
        <BottomCell>{GetBottomContent(feature)}</BottomCell>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: ${weights.semibold};
  font-family: ${families.primaryFont};
  color: ${colors.grey1};
  align-items: stretch;
`;
const CenterWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(220)}px;
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(15)}px;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(16)}px;
  align-items: center;
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(18)}px;
  font-family: ${families.primaryFont};
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(18)}px;
`;
const TooltipIcon = styled.button`
  margin-top: ${({ theme }) => theme.mobileRT.getWidthValue(3)}px;
  height: ${({ theme }) => theme.mobileRT.getWidthValue(23)}px;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(23)}px;
  background: url(${QuestionIcon}) no-repeat center center;
  background-size: ${({ theme }) => theme.mobileRT.getWidthValue(23)}px
    ${({ theme }) => theme.mobileRT.getWidthValue(23)}px;
  border: none;
`;
const Icon = styled.img`
  width: ${({ theme }) => theme.mobileRT.getWidthValue(14)}px;
  &.separate {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;
const TooltipContainer = styled.div`
  background: ${colors.grey1};
  color: ${colors.white};
  font-weight: ${weights.normal};
  font-family: ${families.primaryFont};
  max-width: ${({ theme }) => theme.mobileRT.getWidthValue(340)}px;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(340)}px;
  position: absolute;
  top: calc(100% - 10px);
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(14)}px;
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(16)}px;
  z-index: 1;
  border-radius: 20px;
  display: flex;
`;
const TooltipClose = styled.div`
  height: ${({ theme }) => theme.mobileRT.getWidthValue(14)}px;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(14)}px;
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(8)}px;
  margin-left: ${({ theme }) => theme.mobileRT.getWidthValue(10)}px;
  flex-shrink: 0;
`;
const TooltipCopy = styled.div`
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(15)}px;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(10)}px;
  padding-right: ${({ theme }) => theme.mobileRT.getWidthValue(10)}px;
  padding-left: ${({ theme }) => theme.mobileRT.getWidthValue(10)}px;
  text-align: left;
`;
const Cell = styled.div`
  width: ${({ theme }) => theme.mobileRT.getWidthValue(148)}px;
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(27)}px;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(20)}px;
`;
const TopCell = styled(Cell)`
  display: flex;
  justify-content: center;
  align-self: center;
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(27)}px;
  padding-bottom: 0;
`;
const BottomCell = styled(Cell)`
  display: flex;
  justify-content: center;
  align-self: center;
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(20)}px;
`;
const OptionText = styled.span`
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(20)}px;
  line-height: 1;
  color: ${colors.green1};
  font-weight: ${weights.bold};
  font-family: ${families.primaryFont};
  width: ${({ theme }) => theme.mobileRT.getWidthValue(136)}px;
`;
const OptionIconNumber = styled.span`
  font-family: ${families.primaryFont};
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(20)}px;
  line-height: 1;
  margin-left: ${({ theme }) => theme.mobileRT.getWidthValue(1)}px;
  color: ${colors.green1};
  font-weight: ${weights.bold};
`;
const OptionDisclaimerNumber = styled.sup`
  font-family: ${families.primaryFont};
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(16)}px;
  line-height: 1;
  margin-left: ${({ theme }) => theme.mobileRT.getWidthValue(1)}px;
  color: ${colors.green1};
  font-weight: ${weights.bold};
`;
const FeatureIcon = styled(Image)`
  margin-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(9)}px;
`;
Feature.propTypes = {
  feature: types.FEATURE_PROP_TYPES.isRequired,
  border: PropTypes.bool.isRequired,
  left: types.FEATURE_PROP_TYPES,
  right: types.FEATURE_PROP_TYPES,
  differencesOnly: PropTypes.bool.isRequired,
  footerKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};
Feature.defaultProps = {
  left: undefined,
  right: undefined,
};

export default Feature;
