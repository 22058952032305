import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import types from '../../../../utils/CustomPropTypes';
import { colors, fontVariables } from '../../../../styles/variables';
import Separator from '../../../Separator';
import CONST from '../../../../data/constants';
import Feature from '../Feature';
import { shouldDisplaySubsection } from '../../../../utils/DifferencesUtils/DifferencesUtils';

const { weights, families } = fontVariables;
const { H_LENGTHS } = CONST;

const Subsection = ({
  leftProduct,
  rightProduct,
  subsection,
  pickerBackgroundColor,
  differencesOnly,
  footerKeys,
}) => {
  const { features } = subsection;
  return (
    shouldDisplaySubsection(
      subsection,
      [leftProduct, rightProduct],
      differencesOnly
    ) && (
      <>
        <Row>
          <SubsectionName
            font={subsection.font}
            color={subsection.font_color}
            className={subsection.name ? '' : 'hide'}
          >
            {subsection.name}
          </SubsectionName>
          <Separator.Horizontal length={H_LENGTHS.PARTIAL} thick={2} />
          <Wrapper bgColor={pickerBackgroundColor}>
            {features.map((f, i) => {
              const border = i < features.length - 1;
              const left = leftProduct.product_features?.find(
                pf => pf.feature === f.uuid
              );
              const right = rightProduct.product_features?.find(
                pf => pf.feature === f.uuid
              );
              return (
                <Feature
                  feature={f}
                  border={border}
                  left={left}
                  right={right}
                  key={f.uuid}
                  differencesOnly={differencesOnly}
                  footerKeys={footerKeys}
                />
              );
            })}
          </Wrapper>
        </Row>
      </>
    )
  );
};
const Row = styled.div`
  text-align: center;
  padding-top: ${({ theme, top }) => theme.mobileRT.getWidthValue(top)}px;
  padding-bottom: ${({ theme, bottom }) =>
    theme.mobileRT.getWidthValue(bottom)}px;
`;

const SubsectionName = styled.h3`
  font-family: ${({ font }) => font};
  color: ${({ color }) => color};
  margin: 0;
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(20)}px;
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(24)}px;
  font-weight: ${weights.black};
  font-family: ${families.primaryFont};
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(25)}px;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(22)}px;
  &.hide {
    padding-top: 0;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ bgColor }) => bgColor};
`;

Subsection.propTypes = {
  leftProduct: types.PRODUCT_PROP_TYPES.isRequired,
  rightProduct: types.PRODUCT_PROP_TYPES.isRequired,
  subsection: types.SUBSECTION_PROP_TYPES.isRequired,
  pickerBackgroundColor: PropTypes.string,
  differencesOnly: PropTypes.bool.isRequired,
  footerKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Subsection.defaultProps = {
  pickerBackgroundColor: colors.grey5,
};

export default Subsection;
