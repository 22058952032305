import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';
import CONST from '../../data/constants';

const { V_LENGTHS } = CONST;

const Vertical = ({ bgColor, lineColor, length }) => (
  <Line className={length} bgColor={bgColor} lineColor={lineColor} />
);
const Line = styled.div`
  border-right: 1px solid ${({ lineColor }) => lineColor};
  &.${V_LENGTHS.TOP} {
    margin-top: ${({ theme }) => theme.mobileRT.getWidthValue(27)}px;
  }
  &.${V_LENGTHS.BOTTOM} {
    margin-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(27)}px;
  }
  &.${V_LENGTHS.HALF} {
    margin-top: ${({ theme }) => theme.mobileRT.getWidthValue(27)}px;
    margin-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(27)}px;
  }
`;

Vertical.propTypes = {
  bgColor: PropTypes.string,
  lineColor: PropTypes.string,
  length: PropTypes.string,
};

Vertical.defaultProps = {
  bgColor: colors.grey5,
  lineColor: colors.grey1,
  length: V_LENGTHS.FULL,
};

export default Vertical;
