import {
  shouldDisplaySection,
  shouldDisplaySubsection,
  shouldDisplayFeature,
} from '../DifferencesUtils/DifferencesUtils';

// Uses the product list, sections and differences only values to filter out
// only the legal copies that should be displayed.
// Returns a Map with the key (uuid) and value (copy).
export const getLegalCopies = (products, sections, legalCopies, toggle) => {
  const copies = new Map();
  // If no products or no legal copies, return empty dictionary.
  if (!products || !legalCopies) return copies;
  // Iterate over each section to get all subsections
  sections.forEach(section => {
    // Only get subsections if section should be displayed
    if (shouldDisplaySection(section, products, toggle)) {
      section.subsections.forEach(subsection => {
        // Only get features if subsection should be displayed
        if (shouldDisplaySubsection(subsection, products, toggle)) {
          subsection.features.forEach(feature => {
            // Only check features that should be displayed
            if (shouldDisplayFeature(feature, products, toggle)) {
              // If feature has a legal copy assigned, add it to the map
              if (feature.legal_copy && !copies.has(feature.legal_copy)) {
                copies.set(
                  feature.legal_copy,
                  legalCopies.find(
                    legalCopy => legalCopy.uuid === feature.legal_copy
                  )?.copy
                );
              }
              // If feature has feature options, get options that have a legal copy assigned
              if (
                feature.feature_options &&
                feature.feature_options.length > 0
              ) {
                const optionsWithCopy = feature.feature_options.filter(
                  featureOption => featureOption.legal_copy_uuid != null
                );
                if (optionsWithCopy && optionsWithCopy.length > 0) {
                  // For each option with legal copy, search if at least one product has this feature option selected.
                  optionsWithCopy.forEach(optionWithCopy => {
                    if (
                      products.some(
                        product =>
                          product.product_features.find(
                            productFeature =>
                              productFeature.selected_option ===
                              optionWithCopy.uuid
                          ) != null
                      )
                    ) {
                      // At least one product has this feature option selected, add the legal copy to the map.
                      if (!copies.has(optionWithCopy.legal_copy_uuid)) {
                        copies.set(
                          optionWithCopy.legal_copy_uuid,
                          legalCopies.find(
                            legalCopy =>
                              legalCopy.uuid === optionWithCopy.legal_copy_uuid
                          )?.copy
                        );
                      }
                    }
                  });
                }
              }
            }
          });
        }
      });
    }
  });
  return copies;
};

export default getLegalCopies;
