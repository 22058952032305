import { getEcommercePayload } from './helpers';

export const onTableIsVisible = (dataLayer = {}, products = []) => {
  try {
    dataLayer.push({
      event: 'comparisonChart',
      action: 'Scroll to Compare Roomba',
      Label: products.map(p => p.short_name).join('|'),
      ...getEcommercePayload(products),
    });
  } catch (e) {
    console.error(e);
  }
};

export const onShowOnlyDifferences = (dataLayer = {}, toggleStatus = false) => {
  try {
    dataLayer.push({
      event: 'comparisonChart',
      action: 'Roomba | Show Only Differences',
      Label: toggleStatus ? 'Toggled on' : 'Toggled off',
    });
  } catch (e) {
    console.error(e);
  }
};

export const onSelectProduct = (dataLayer = {}, product = {}) => {
  try {
    dataLayer.push({
      event: 'comparisonChart',
      action: 'Roomba Selection',
      Label: product.short_name,
    });
  } catch (e) {
    console.error(e);
  }
};

export const onUnselectProduct = (dataLayer = {}, product = {}) => {
  try {
    dataLayer.push({
      event: 'comparisonChart',
      action: 'Roomba Unselection',
      Label: product.short_name,
    });
  } catch (e) {
    console.error(e);
  }
};

export const onClickShop = (
  dataLayer = {},
  product = {},
  isExploreLink = false
) => {
  try {
    dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: 'Comparison Chart' },
          products: [
            {
              name: product.short_name,
              id: isExploreLink ? product.explore_sku : product.sku,
            },
          ],
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export default {
  onTableIsVisible,
  onSelectProduct,
  onShowOnlyDifferences,
  onUnselectProduct,
  onClickShop,
};
