export const getEcommercePayload = (products = []) => {
  try {
    return {
      ecommerce: {
        impressions: products.map((product, index) => ({
          name: product.short_name || '',
          id: product.sku,
          list: 'Comparison Chart',
          position: index + 1,
        })),
      },
    };
  } catch (e) {
    return {};
  }
};

export default {
  getEcommercePayload,
};
