import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import types from '../../../utils/CustomPropTypes';
import { colors, fontVariables } from '../../../styles/variables';
import Separator from '../../Separator';
import CONST from '../../../data/constants';
import analytics from '../../../utils/analytics';

const { H_LENGTHS } = CONST;

const { weights, families } = fontVariables;

const MobileProductColumn = ({
  leftProduct,
  rightProduct,
  pickerBackgroundColor,
  shopLabel,
  exploreLabel,
  showPrice,
  showProductSubtitle,
  showShopSelectContainer,
  shopButtonPrimaryColor,
  shopButtonPrimaryBorderColor,
  shopButtonTextPrimaryColor,
  shopSelectButtonPrimaryColor,
  shopSelectButtonPrimaryBorderColor,
  shopSelectButtonTextPrimaryColor,
}) => {
  const isEmpty =
    !showProductSubtitle &&
    !showPrice &&
    !leftProduct.link &&
    !rightProduct.link &&
    !showShopSelectContainer;

  return (
    <CardsContainer bgColor={pickerBackgroundColor} isEmpty={isEmpty}>
      {[leftProduct, rightProduct].map(product => (
        <Card
          key={`mobileProductCard-${product.uuid}`}
          bgColor={pickerBackgroundColor}
        >
          {showProductSubtitle && (
            <Description>{product.description}</Description>
          )}
          {showPrice && <Price>{product.price || '$000.00'}</Price>}
          {(leftProduct.link || rightProduct.link) && (
            <ButtonContainer>
              {!!product.link && (
                <Button
                  key={`mobileShopButton-${product.uuid}`}
                  href={product.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => analytics.trackClickShop(product)}
                  shopButtonPrimaryColor={shopButtonPrimaryColor}
                  shopButtonPrimaryBorderColor={shopButtonPrimaryBorderColor}
                  shopButtonTextPrimaryColor={shopButtonTextPrimaryColor}
                >
                  {shopLabel}
                </Button>
              )}
            </ButtonContainer>
          )}
          {showShopSelectContainer && product.display_explore && (
            <>
              {(leftProduct.link || rightProduct.link || !!product.link) && (
                <Separator.Horizontal length={H_LENGTHS.HALF} />
              )}
              <Payments>{product.explore_copy}</Payments>
              <Button
                href={product.explore_link}
                target="_blank"
                rel="noreferrer"
                onClick={() => analytics.trackClickShop(product, true)}
                shopButtonPrimaryColor={shopSelectButtonPrimaryColor}
                shopButtonPrimaryBorderColor={
                  shopSelectButtonPrimaryBorderColor
                }
                shopButtonTextPrimaryColor={shopSelectButtonTextPrimaryColor}
              >
                {product.shop_select_button_copy || exploreLabel}
              </Button>
            </>
          )}
        </Card>
      ))}
    </CardsContainer>
  );
};

const Card = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.mobileRT.getWidthValue(25)}px;
`;

const CardsContainer = styled.div`
  &:not(:empty) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${({ theme }) => theme.mobileRT.getWidthValue(25)}px;
    background-color: ${({ bgColor }) => bgColor};
    padding: ${({ theme, isEmpty }) =>
      isEmpty
        ? 0
        : `${theme.mobileRT.getWidthValue(27)}px ${theme.mobileRT.getWidthValue(
            20
          )}px`};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.grey1};

  text-align: center;
  padding-left: ${({ theme }) => theme.mobileRT.getWidthValue(23)}px;
  padding-right: ${({ theme }) => theme.mobileRT.getWidthValue(23)}px;
  margin-top: ${({ theme, top }) => theme.mobileRT.getWidthValue(top)}px;
  margin-bottom: ${({ theme, bottom }) =>
    bottom && `${theme.mobileRT.getWidthValue(bottom)}px`};
`;

const Cell = styled.div`
  width: ${({ theme }) => theme.mobileRT.getWidthValue(187)}px;
  display: flex;
  flex-direction: Column;
  margin: 0 auto;
`;

const Description = styled(Cell)`
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(20)}px;
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(24)}px;
  letter-spacing: 0.2px;
  font-weight: ${weights.bold};
`;

const Price = styled(Cell)`
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(18)}px;
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(18)}px;
`;

const Payments = styled(Cell)`
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(18)}px;
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(20)}px;
  text-align: center;
  width: auto;
`;

const ButtonContainer = styled.div`
  height: ${({ theme }) => theme.mobileRT.getWidthValue(46)}px;
  display: flex;
`;

const Button = styled.a`
  font-family: ${families.primaryFont};
  text-align: center;
  height: ${({ theme }) => theme.mobileRT.getWidthValue(46)}px;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(124)}px;
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(17)}px;
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(47)}px;
  letter-spacing: 0.17px;
  font-weight: ${weights.semibold};
  color: ${({ shopButtonTextPrimaryColor }) => shopButtonTextPrimaryColor};
  border-radius: 28px;
  background-color: ${({ shopButtonPrimaryColor }) => shopButtonPrimaryColor};
  margin: 0 auto;
  border: 1px solid;
  border-color: ${({ shopButtonPrimaryBorderColor }) =>
    shopButtonPrimaryBorderColor};
  text-decoration: none;
  :active,
  :focus,
  :visited {
    color: ${({ shopButtonTextPrimaryColor }) => shopButtonTextPrimaryColor};
  }
  ${Row} & {
    margin-right: ${({ theme }) => theme.mobileRT.getWidthValue(19)}px;
    margin-left: auto;
    &:first-of-type {
      margin-left: ${({ theme }) => theme.mobileRT.getWidthValue(19)}px;
      margin-right: auto;
    }
  }
`;

MobileProductColumn.propTypes = {
  leftProduct: types.PRODUCT_PROP_TYPES.isRequired,
  rightProduct: types.PRODUCT_PROP_TYPES.isRequired,
  pickerBackgroundColor: PropTypes.string.isRequired,
  shopLabel: PropTypes.string.isRequired,
  exploreLabel: PropTypes.string.isRequired,
  showPrice: PropTypes.bool.isRequired,
  showProductSubtitle: PropTypes.bool.isRequired,
  showShopSelectContainer: PropTypes.bool,
  shopButtonPrimaryColor: PropTypes.string.isRequired,
  shopButtonPrimaryBorderColor: PropTypes.string.isRequired,
  shopButtonTextPrimaryColor: PropTypes.string.isRequired,
  shopSelectButtonPrimaryColor: PropTypes.string.isRequired,
  shopSelectButtonPrimaryBorderColor: PropTypes.string.isRequired,
  shopSelectButtonTextPrimaryColor: PropTypes.string.isRequired,
};

MobileProductColumn.defaultProps = {
  showShopSelectContainer: false,
};

export default MobileProductColumn;
