import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CustomPropTypes from '../../../../utils/CustomPropTypes';
import { colors, fontVariables } from '../../../../styles/variables';

const { weights, families } = fontVariables;

const ProductPicker = ({ product, products, setProduct }) => {
  const [isOpen, setOpen] = useState(false);

  const handleSelectClick = () => {
    setOpen(!isOpen);
  };

  const handleSelectBlur = () => {
    setOpen(false);
  };

  return (
    <Container className={isOpen ? 'open' : ''}>
      <SelectContainer>
        <Select onClick={handleSelectClick} onBlur={handleSelectBlur}>
          <TextColumn>
            <Title dangerouslySetInnerHTML={{ __html: product.name }} />
            <SubTitle>{product.product_kind}</SubTitle>
          </TextColumn>
          <DropDownArrow>
            <img
              src="https://app.irobot.mdsrv.media/public-assets/chartv3/assets/images/svgs/ArrowDown.svg"
              alt={isOpen ? 'close' : 'open'}
            />
          </DropDownArrow>
        </Select>
      </SelectContainer>
      <Dropdown>
        {products &&
          products.map(p => (
            <Select
              key={p.uuid}
              onClick={() => setProduct(p.uuid)}
              className={product.uuid === p.uuid ? 'selected' : ''}
            >
              <TextColumn>
                <Title dangerouslySetInnerHTML={{ __html: p.name }} />
                <SubTitle>{p.product_kind}</SubTitle>
              </TextColumn>
            </Select>
          ))}
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 0 ${({ theme }) => theme.mobileRT.getWidthValue(15)}px;
  border-bottom: 1px solid ${colors.grey1};
  :first-child {
    margin-left: ${({ theme }) => theme.mobileRT.getWidthValue(20)}px;
  }
  :last-child {
    margin-right: ${({ theme }) => theme.mobileRT.getWidthValue(20)}px;
  }
`;

const Dropdown = styled.div`
  overflow: hidden;
  max-height: 0;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(145.5)}px;
  position: absolute;
  background: ${colors.grey4};
  transition: all 0.5s ease-out;
  opacity: 0.5;
  z-index: 1;
  overflow-y: scroll;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  ${Container}.open & {
    transition-duration: 1.2s;
    max-height: ${({ theme }) => theme.mobileRT.getWidthValue(485)}px;
    opacity: 1;
  }
`;

const SelectContainer = styled.div`
  height: ${props => (props.selectHeight ? `${props.selectHeight}px` : 'auto')};
  overflow: hidden;
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(13)}px;
`;

const Select = styled.button`
  background: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.mobileRT.getWidthValue(11)}px;
  padding-right: ${({ theme }) => theme.mobileRT.getWidthValue(5)}px;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(7)}px;
  height: auto;
  cursor: pointer;
  border: 0;
  transition: all 0.5s ease-out;
  :first-of-type {
    border-radius: 10px 10px 0 0;
  }
  ${Dropdown} &:last-of-type {
    border-bottom: none;
  }
  ${Dropdown} & {
    justify-content: center;
    border-bottom: 1px solid ${colors.grey1};
  }
  ${Dropdown} &:first-of-type {
    border-radius: 0;
  }
  ${Container}.open & {
    background: ${colors.grey4};
    transition-duration: 1.2s;
    &.selected {
      background: ${colors.green1};
    }
  }
`;

const TextColumn = styled.div`
  width: 100%;
  text-align: left;
  color: ${colors.grey1};
  ${Select}.selected & {
    color: ${colors.white};
  }
`;

const Title = styled.div`
  font-family: ${families.primaryFont};
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(10)}px;
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(18)}px;
  font-weight: ${weights.bold};
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(19)}px;
  margin: 0;
  ${Dropdown} & {
    font-size: ${({ theme }) => theme.mobileRT.getFontSize(16)}px;
    line-height: ${({ theme }) => theme.mobileRT.getFontSize(18)}px;
  }

  sup {
    font-size: ${({ theme }) => theme.mobileRT.getWidthValue(8)}px;
  }
`;

const SubTitle = styled.div`
  font-family: ${families.primaryFont};
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(12)}px;
  line-height: ${({ theme }) => theme.mobileRT.getFontSize(18)}px;
  font-weight: ${weights.normal};
  margin: 0;
  text-transform: capitalize;
`;

const DropDownArrow = styled.div`
  img {
    display: block;
    margin-top: ${({ theme }) => theme.mobileRT.getWidthValue(18)}px;
    transition: all 0.5s ease-out;
    ${Container}.open & {
      transform: rotate(180deg);
      transition-duration: 1.2s;
    }
  }
`;

ProductPicker.propTypes = {
  product: CustomPropTypes.PRODUCT_PROP_TYPES,
  products: PropTypes.arrayOf(CustomPropTypes.PRODUCT_PROP_TYPES),
  setProduct: PropTypes.func,
};

ProductPicker.defaultProps = {
  product: {},
  products: [],
  setProduct: () => {},
};

export default ProductPicker;
