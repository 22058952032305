import PropTypes from 'prop-types';

const LEGALCOPY_PROP_TYPES = PropTypes.shape({
  uuid: PropTypes.string,
  copy: PropTypes.string,
});

LEGALCOPY_PROP_TYPES.defaultProps = {
  uuid: '',
  copy: '',
};

export default LEGALCOPY_PROP_TYPES;
