import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import useResponsiveTools from '../../hooks/useResponsiveTools';
import { CompContext } from '../../context';

function App({ children, compData: chartData, isLoading, isError }) {
  const responsiveUtilities = useResponsiveTools();
  return (
    <ThemeProvider theme={responsiveUtilities}>
      <CompContext.Provider value={{ chartData, isLoading, isError }}>
        <div id="irobot-comp-chart-v2">{children}</div>
      </CompContext.Provider>
    </ThemeProvider>
  );
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  compData: PropTypes.object,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

App.defaultProps = {
  compData: {},
  isLoading: false,
  isError: false,
};

export default App;
